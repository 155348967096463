import CelularesView from "../CelularesView.vue";
import DashboardRoutes from "../Dashboard/Routes";
import BaseBatchRoutes from "../BaseBatch/Routes";
import StatusRoutes from "../Status/Routes";
import SellersRoutes from "../Sellers/Routes";
import SupervisorsRoutes from "../Supervisors/Routes";
import ManagersRoutes from "../Managers/Routes";
import SalesPointsRoutes from "../SalesPoints/Routes";
import PushersRoutes from "../Pushers/Routes";
import DeliverablesRoutes from "../Deliverables/Routes";
import SchemeRoutes from "../Scheme/Routes";
import AuditLogRoutes from "../AuditLog/Routes";
import ConfigRoutes from "../Config/Routes";
import CodeRelationshipsRoutes from '../CodeRelationships/Routes'

let children = [];

children = children.concat(
  DashboardRoutes,
  BaseBatchRoutes,
  StatusRoutes,
  SellersRoutes,
  SupervisorsRoutes,
  ManagersRoutes,
  SalesPointsRoutes,
  PushersRoutes,
  DeliverablesRoutes,
  SchemeRoutes,
  AuditLogRoutes,
  ConfigRoutes,
  CodeRelationshipsRoutes
);

const routes = [
  {
    path: "/celulares-ad",
    component: CelularesView,
    children: children,
  },
];

export default routes;
