import ConfigView from "../ConfigView.vue";
import ClusterList from "../Pages/ClusterList.vue";
import ClusterEditor from "../Pages/ClusterEditor.vue";
import ZonalList from "../Pages/ZonalList.vue";
import ZonalEditor from "../Pages/ZonalEditor.vue";
import TypeList from "../Pages/TypeList.vue";
import TypeEditor from "../Pages/TypeEditor.vue";

const routes = [
  {
    path: "mantenimiento",
    component: ConfigView,
    name: "config",
    children: [
      {
        path: "clusters",
        name: "celulares-config-cluster-list",
        component: ClusterList,
      },
      {
        path: "clusters/crear",
        name: "celulares-config-cluster-create",
        component: ClusterEditor,
      },
      {
        path: "clusters/:id/editar",
        name: "celulares-config-cluster-edit",
        component: ClusterEditor,
      },
      {
        path: "zonales",
        name: "celulares-config-zonal-list",
        component: ZonalList
      },
      {
        path: "zonales/crear",
        name: "celulares-config-zonal-create",
        component: ZonalEditor
      },
      {
        path: "zonales/:id/editar",
        name: "celulares-config-zonal-edit",
        component: ZonalEditor,
      },
      {
        path: "tipos",
        name: "celulares-config-types-list",
        component: TypeList
      },
      {
        path: "tipos/crear",
        name: "celulares-config-types-create",
        component: TypeEditor
      },
      {
        path: "tipos/:id/editar",
        name: "celulares-config-types-edit",
        component: TypeEditor
      }
    ],
  },
];

export default routes;
