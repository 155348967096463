<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Carga Masiva de Impulsadores</h1>
    </div>

    <div>
      <b-card
        tag="article"
        no-body
        style="max-width: 640px"
        class="custom-card"
      >
        <b-card-header
          header-tag="section"
          class="custom-card-header d-flex justify-content-between align-items-center"
        >
          <h3>Carga masiva de impulsadores</h3>

          <ButtonWithTooltip
            text="Descargar plantilla"
            @click="downloadTemplate"
            class="m-0"
            tooltip-variant="download-template"
          >
            <template slot="icon">
              <DownloadTableIcon/>
            </template>
          </ButtonWithTooltip>
        </b-card-header>
        <b-card-body class="custom-card-body p-0">
          <form class="custom-form" @submit.prevent="save" novalidate>
            <div class="form-section">
              <section class="file-container">
                <FileInput
                  class="w-100 mt-2"
                  id-file="file"
                  :acceptFile="['.xlsx']"
                  @onChange="onFileChange"
                />
                <FormError
                  v-if="$v.file.$error && !$v.file.required"
                  message="El archivo es requerido"
                />
              </section>

              <div class="form-group-buttons text-right">
                <a
                  @click="$router.go(-1)"
                  class="button button-light"
                >
                  Cancelar
                </a>
                <button class="button button-primary" type="submit">
                  Procesar
                </button>
              </div>
              <div class="form-errors mt-3" v-if="errors.length > 0">
                <b
                ><span class="error" v-if="errors.length > 0"
                >Errores: {{ errors.length }}</span
                ></b
                ><br/>
                <span
                  v-for="(e, i) in errors"
                  class="file-error"
                  :key="`${i}-error`"
                >{{ e }}<br
                /></span>
              </div>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { Alert, ButtonWithTooltip, DownloadTableIcon, FileInput, FormError, Util } from "wize-admin";

import { required } from "vuelidate/lib/validators";

import fileDownload from "js-file-download";
import { BatchService } from "../../Core/Services";

export default {
  components: {
    FileInput,
    ButtonWithTooltip,
    FormError,
    DownloadTableIcon
  },
  name: "PaymentBatchUpload",
  data() {
    return {
      file: null,
      errors: [],
    };
  },
  methods: {
    async downloadTemplate() {
      try {
        await this.$store.dispatch("app/loading", true);
        const response = await BatchService.downloadTemplate();
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },

    async save() {
      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }

      await this.$store.dispatch("app/loading", true);
      this.errors = [];
      const data = {
        file: this.file,
      };
      try {
        const resp = await BatchService.uploadBatch(data);
        if (resp.payload.messages && resp.payload.messages.length > 0) {
          this.errors = resp.payload.messages;
          return;
        }
        Alert.success("Se cargó la base correctamente");
        this.$router.push({name: "celulares-base-batch"});
      } catch (e) {
        this.errors = [];
        if (e.errors && e.errors.message) this.errors.push(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    onFileChange(files) {
      this.$v.file.$reset();
      if (Array.isArray(files)) {
        this.file = files[0];
      } else {
        this.file = files;
      }
    },
  },
  validations: {
    file: {required},
  },
  async created() {

  },
};
</script>

<style lang="stylus" scoped>
</style>
