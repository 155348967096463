+
<template>
  <div class="page-container base-batch-list">
    <div class="page-title-container">
      <h1 class="page-title">
        Detalle del código de pago: {{ $route.params.paymentCode }}
      </h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <button
                type="button"
                class="filter d-flex align-items-center justify-content-between border-0"
                @click="showFilters = !showFilters"
              >
                <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                <div>Filtrar</div>
              </button>

              <div class="d-flex d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold mr-0"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>

                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold"
                  v-b-tooltip.hover
                  title="Descargar"
                  @click="downloadReport()"
                >
                  <i class="ri-download-2-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold mr-0"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>

              <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold"
                  v-b-tooltip.hover
                  title="Descargar"
                  @click="downloadReport()"
                >
                  <i class="ri-download-2-line"></i>
                </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por nombre o número de documento"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />
            </div>
          </section>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filterList"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="text-white p-0 bg-transparent border-0 ml-2"
              @click="deleteFilter(name)"
            >
              <i class="ri-close-circle-line"></i>
            </button>
          </span>
        </div>
      </div>

      <div class="mb-0">
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>ID Resultado</span>
            </div>
            <div>
              <span>Segmento</span>
            </div>
            <div>
              <span>Nombres y Apellidos</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span>{{ o.code }}</span>
                </div>
                <div>
                  <span>{{ o.segment }}</span>
                </div>
                <div>
                  <span>{{ o.pusherNames }}</span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="custom-table-mobile-options mt-3">
                <a
                  href=""
                  @click.prevent="openPayedModal(o.id)"
                  class="mr-2"
                  v-if="o.status != LocalConstants.PaymentStatus.PAID.key"
                  >Marcar como pagado</a
                >
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Tipo de documento:</span
                    >
                    <span>{{
                      getIdentifierType(o.pusherIdentifierType).label
                    }}</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Número de documento:</span
                    >
                    <span>{{ o.pusherIdentifier }}</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Monto:</span
                    >
                    <span v-decimal:pen="o.amount" v-if="o.amount"></span>
                    <span v-else>-</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Monto abonado:</span
                    >
                    <span
                      v-decimal:pen="o.payedAmount"
                      v-if="o.payedAmount"
                    ></span>
                    <span v-else>-</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Fecha de abono:</span
                    >
                    <span v-localDate="o.payedAt" v-if="o.payedAt"></span>
                    <span v-else></span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Medio de pago:</span
                    >
                    <span>-</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Estado:</span
                    >
                    <span :class="`badge ${getStatus(o.status).color}`">{{
                      getStatus(o.status).label
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th>ID Resultado</th>
              <th>Segmento</th>
              <th>
                Tipo de <br />
                documento
              </th>
              <th>
                Número de <br />
                documento
              </th>
              <th>Nombres y Apellidos</th>
              <th>Monto</th>
              <th>Monto abonado</th>
              <th>
                Fecha de <br />
                abono
              </th>
              <th>
                Medio de <br />
                pago
              </th>
              <th>Status</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="cell-center px-1">{{ o.code }}</td>
              <td class="cell-center px-1">{{ o.segment }}</td>
              <td class="cell-center px-1">
                {{ getIdentifierType(o.pusherIdentifierType).label }}
              </td>
              <td class="text-center">{{ o.pusherIdentifier }}</td>
              <td class="pl-4">{{ o.pusherNames }}</td>
              <td class="cell-center">
                <span v-decimal:pen="o.amount" v-if="o.amount"></span>
                <span v-else>-</span>
              </td>
              <td class="cell-center">
                <span v-decimal:pen="o.payedAmount" v-if="o.payedAmount"></span>
                <span v-else>-</span>
              </td>
              <td class="cell-center">
                <span v-localDate="o.payedAt" v-if="o.payedAt"></span>
                <span v-else></span>
              </td>
              <td class="cell-center">{{ getPaymentMethod(o.paymentMethod).label }}</td>
              <td>
                <span :class="`badge ${getStatus(o.status).color}`">{{
                      getStatus(o.status).label
                    }}</span>
              </td>
              <td class="cell-center d_option pr-md-2 position-relative">
                <DropdownTable v-if="o.status != LocalConstants.PaymentStatus.PAID.key">
                  <template v-slot:dropdown-content>
                    <DropdownItem
                      text="Marcar como pagado"
                      @click="openPayedModal(o.code)"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
          v-if="list.length"
        />

        <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>
    <div class="row buttons-container mb-0 mt-3">
      <div class="col text-right">
        <router-link
          :to="{ name: 'celulares-status-details', params: {periodId} }"
          class="button button-light"
        >
          Volver
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import fileDownload from "js-file-download"

import {
  Alert,
  Constants,
  FilterMenu,
  FilterMixin,
  PaginationMixin,
  SearchBar,
  Util,
  DropdownTable,
  DropdownItem,
} from "wize-admin";

import { StatusService } from "../../Core/Services";

import { Constants as LocalConstants } from "@/core/utils";

export default {
  components: {
    SearchBar,
    FilterMenu,
    DropdownTable,
    DropdownItem,
  },
  data() {
    return {
      LocalConstants,
      periodId: this.$route.params.periodId,
      list: [],
      tooltipType: null,
      filterOptions: [
        {
          label: "Tipo de documento",
          key: "identifierTypes",
          type: Constants.TypeFilters.MULTIPLE,
          options: [
            LocalConstants.IdentifierType.DNI,
            LocalConstants.IdentifierType.CE,
            LocalConstants.IdentifierType.PASAPORTE,
            LocalConstants.IdentifierType.CARNE_REFUGIO,
            LocalConstants.IdentifierType.CARNE_IDENTIDAD,
            LocalConstants.IdentifierType.CARNE_PERMANENCIA,
            LocalConstants.IdentifierType.DNI_EXTRANJERO,
          ],
        },
      ],
    };
  },
  mixins: [FilterMixin, PaginationMixin],
  methods: {
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.PaymentStatus);
    },
    openPayedModal(paymentId) {
      let periodId = this.$route.params.periodId;
      let paymentCode = this.$route.params.paymentCode

      this.$router.push({
        name: "celulares-status-payment-mark-as-paid",
        params: {periodId, paymentCode, paymentId},
      });
    },
    getIdentifierType(key) {
      return Util.searchValue(key, Constants.IdentifierType);
    },
    getPaymentMethod(key) {
      return Util.searchValue(key, LocalConstants.PaymentMethods);
    },
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        const params = {
          page: this.page - 1,
          size: this.pageSize,
        };

        const data = { search: this.search, ...this.filters };
        const { periodId, paymentCode } = this.$route.params;
        const response = await StatusService.filterPayments(
          periodId,
          paymentCode,
          data,
          params
        );

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async downloadReport() {
      try {
        await this.$store.dispatch("app/loading", true);
        const data = { search: this.search, ...this.filters };
        const { periodId, paymentCode } = this.$route.params;
        const response = await StatusService.downloadPaymentCodeDetailDetail(periodId, paymentCode, data);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    }
  },
  async mounted() {
    const { periodId, paymentCode } = this.$route.params;
    if (periodId && paymentCode) {
      await this.loadData();
    }
  },
  async created() {},
};
</script>

<style lang="stylus" scoped></style>
