const Errors = {
  SERVER_ERROR: "Ocurrió un error en la petición, por favor intenta nuevamente.",
}

const IdentifierType = {
  DNI: {key: 1, label: 'DNI'},
  CE: {key: 4, label: 'CARNE DE EXTRANJERIA'},
  RUC: {key: 6, label: 'RUC'},
  PASAPORTE: {key: 7, label: 'PASAPORTE'},
  CARNE_REFUGIO: {key: 9, label: 'CARNE DE SOLICITUD DE REFUGIO'},
  CARNE_IDENTIDAD: {key: 22, label: 'CARNE DE IDENTIDAD – RR.EE.'},
  CARNE_PERMANENCIA: {key: 23, label: 'CARNE PERMISO TEMP. PERMANENCIA'},
  DNI_EXTRANJERO: {key: 24, label: 'DOC. DE IDENTIDAD EXTRANJERO'},
  LIBRETA_MILITAR: {key: 90, label: 'LIBRETA MILITAR'},
  CARNET_MILITAR: {key: 91, label: 'CARNET MILITAR'},
  OTROS: {key: 0, label: 'OTROS'},
}

const SellersStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-red'},
}

const SellersTypes = {
  SALES_EXECUTIVE: {key: 1, label: 'Ejecutivo de venta'},
  RELIEF_SALES_EXECUTIVE: {key: 2, label: 'Ejecutivo de venta volante'}
}

const SupervisorsStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'}

}

const SupervisorTypes = {
  AD: {key: 1, label: 'SUPERVISOR DE VENTAS AD', color: 'badge-green'},
  PUSHER: {key: 2, label: 'SUPERVISOR DE VENTAS MULTIMARCA', color: 'badge-yellow'}
}

const ManagerStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'},
}

const PdvStatus = {
  ACTIVE: {
    key: 1,
    label: "ACTIVO",
    color: "badge-green",
    action: "Suspender",
  },
  INACTIVE: {
    key: 2,
    label: "INACTIVO",
    color: "badge-danger",
    action: "Activar",
  },
}

const ClusterList = {
  DIAMOND: {key: 1, label: 'DIAMANTE'},
  TOP: {key: 2, label: 'TOP'},
  PLATINUM: {key: 3, label: 'PLATINO'},
  GOLD: {key: 4, label: 'ORO'},
  SILVER: {key: 5, label: 'PLATA'}
}

const PushersStatus = {
  ACTIVE: {
    key: 1,
    label: "ACTIVO",
    color: "badge-green",
    action: "Suspender",
  },
  INACTIVE: {
    key: 2,
    label: "INACTIVO",
    color: "badge-danger",
    action: "Activar",
  },
}

const DeliverablesStatus = {
  ACTIVE: {
    key: 1,
    label: "ACTIVO",
    color: "badge-green",
    action: "Suspender",
  },
  INACTIVE: {
    key: 2,
    label: "INACTIVO",
    color: "badge-danger",
    action: "Activar",
  }
}

const ApplicationTypes = {
  PDV: {
    key: 1,
    label: "PDV"
  },
  PUSHERS: {
    key: 2,
    label: "Impulsadores"
  },
  BOTH: {
    key: 3,
    label: "Ambos"
  }
}

const CyclesList = {
  MONTHLY: {
    key: 1,
    label: "Mensual"
  },
  FORTNIGHTLY: {
    key: 2,
    label: "Quincenal"
  },
  TEN: {
    key: 3,
    label: "10 días"
  },
  OTHERS: {
    key: 4,
    label: "Otros ciclos personalizados"
  }
}

const SchemeStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-red'},
}

const PaymentMethods = {
  BANK_TRANSFER: {
    key: "bank-transfer",
    label: "Transferencia bancaria",
  },
  BANK_DEPOSIT: {
    key: "bank-deposit",
    label: "Depósito bancario",
  },
  CASH_PAYMENT: {
    key: "cash-payment",
    label: "Pago en efectivo",
  },
  YAPE: {
    key: "yape",
    label: "Yape",
  },
  PLIN: {
    key: "plin",
    label: "Plin",
  },
  CHECK: {
    key: "check",
    label: "Cheque",
  },
};

const AuditLogAction = {
  CREATE: {key: "CREATE", label: "CREAR", color: "badge-gray"},
  UPDATE: {key: "UPDATE", label: "ACTUALIZAR", color: "badge-yellow"},
  DELETE: {key: "DELETE", label: "ELIMINAR", color: "badge-red"},
  LOGIN: {key: "LOGIN", label: "INICIO DE SESIÓN", color: "badge-green"},
};

const ClusterStatus = {
  ACTIVE: {
    key: 1,
    label: "ACTIVO",
    color: "badge-green",
    action: "Suspender",
  },
  INACTIVE: {
    key: 2,
    label: "INACTIVO",
    color: "badge-danger",
    action: "Activar",
  }
}

const SegmentType = {
  PDV: {
    key: 1,
    label: "PUNTO DE VENTA"
  },
  PUSHER: {
    key: 2,
    label: "IMPULSADOR",
  }
}


const ZonalStatus = {
  ACTIVE: {
    key: 1,
    label: "ACTIVO",
    color: "badge-green",
    action: "Suspender",
  },
  INACTIVE: {
    key: 2,
    label: "INACTIVO",
    color: "badge-danger",
    action: "Activar",
  }
}

const TypesStatus = {
  ACTIVE: {
    key: 1,
    label: "ACTIVO",
    color: "badge-green",
    action: "Suspender",
  },
  INACTIVE: {
    key: 2,
    label: "INACTIVO",
    color: "badge-danger",
    action: "Activar",
  }
}

const PaymentStatus = {
  PENDING: { key: 1, label: "PENDIENTE", color: "badge-gray" },
  PAID: { key: 2, label: "PAGADO", color: 'badge-purple' }
};

export default {
  Errors,
  ZonalStatus,
  IdentifierType,
  SellersStatus,
  SellersTypes,
  SupervisorsStatus,
  SupervisorTypes,
  ManagerStatus,
  PdvStatus,
  ClusterList,
  PushersStatus,
  DeliverablesStatus,
  ApplicationTypes,
  CyclesList,
  SchemeStatus,
  PaymentMethods,
  AuditLogAction,
  ClusterStatus,
  TypesStatus,
  SegmentType,
  PaymentStatus
}
