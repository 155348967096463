<template>
  <page-base>
    <router-view></router-view>
  </page-base>
</template>

<script>
import {PageBase} from "../Core/Components";

export default {
  name: "AuditLogView",
  components: {PageBase}
}
</script>

<style scoped lang="stylus">
</style>
