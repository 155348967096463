<template>
  <div class="page-container zonal-editor">
    <div class="page-title-container">
      <h1 class="page-title">{{ editing ? "Editar Tipo" : "Nuevo tipo" }}</h1>
    </div>
    <div>
      <b-card
        tag="article"
        no-body
        style="max-width: 640px"
        class="custom-card"
      >
        <b-card-header
          header-tag="section"
          class="custom-card-header card-header d-flex align-items-center"
        >
          <h3>{{ editing ? "Datos del tipo" : "Datos del nuevo tipo" }}</h3>
        </b-card-header>
        <b-card-body class="custom-card-body p-0">
          <form
            class="custom-form"
            @submit.prevent="save"
            novalidate
          >
            <section class="form-section">
              <div class="form-group">
                <label for="type">Segmento *</label>

                <FormSelect
                  id="type"
                  :show-error="
                            $v.form.segment.$error && !$v.form.segment.required
                          "
                  v-model="form.segment"
                  :items="segmentList"
                  defaultOption="Elegir segmento"
                />

                <form-error
                  message="Segmento es requerido"
                  v-if="
                            $v.form.segment.$error && !$v.form.segment.required
                          "
                ></form-error>
              </div>

              <div class="form-group mb-0">
                <label for="description">Descripción *</label>
                <input
                  id="description"
                  type="text"
                  v-model.trim="form.description"
                  autocomplete="off"
                  placeholder="Ingresar descripción"
                  class="custom-form-control"
                  :class="{
                    'custom-form-control-error': $v.form.description.$error,
                  }"
                />
                <FormError
                  v-if="
                    $v.form.description.$error && !$v.form.description.required
                  "
                  message="Descripción es requerida"
                />
              </div>
              <div class="form-group-buttons text-right">
                <a
                  @click="$router.go(-1)"
                  class="button button-light"
                >
                  {{ editing ? "Volver" : "Cancelar" }}
                </a>
                <button class="button button-primary" type="submit">
                  Guardar
                </button>
              </div>
            </section>
          </form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import { Alert, FormError, FormSelect } from "wize-admin";

import { required } from "vuelidate/lib/validators";
import { TypeService } from "../../Core/Services";
import { Constants as LocalConstants } from "@/core/utils";

export default {
  components: {
    FormError,
    FormSelect,
  },
  data() {
    return {
      form: {
        segment: null,
        description: null,
      },
      editing: false,
      segmentList: [
        LocalConstants.SegmentType.PDV,
        LocalConstants.SegmentType.PUSHER,
      ]
    };
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;

      try {
        this.$store.dispatch("app/loading", true);
        const data = { ...this.form };
        let response;
        if (!this.editing) response = await TypeService.save(data);
        else response = await TypeService.update(this.$route.params.id, data);
        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "celulares-config-types-edit",
            params: { id: response.payload.id },
          });
          this.editing = true;
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await TypeService.get(id);
        const payload = response.payload;
        this.form = { ...payload };
        this.editing = true;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
        this.$router.push({ name: "celulares-config-types-list" });
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  validations: {
    form: {
      segment: { required },
      description: { required },
    },
  },
  mounted() {
    if (this.$route.params.id) this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped>
@import "../Styles/zonal-editor"
</style>
