<template>
  <div class="page-container product-editor">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar esquema" : "Nuevo esquema" }}
      </h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12">
          <b-card
            tag="article"
            no-body
            style="max-width: 760px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{
                  editing
                    ? "Datos del supervisor"
                    : "Datos del nuevo supervisor"
                }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body px-0 pb-0">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section pt-0">
                  <h2 class="custom-card-subtitle mb-4">Información general</h2>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="name">Nombre del Esquema *</label>

                        <FormInput
                          id="name"
                          type="text"
                          placeholder="Ingresar nombre del esquema"
                          :show-error="
                            $v.form.name.$error && !$v.form.name.required
                          "
                          v-model="form.name"
                        />

                        <form-error
                          message="Nombre del esquema es requerido"
                          v-if="$v.form.name.$error && !$v.form.name.required"
                        ></form-error>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="aplicationType">Tipo de Aplicación *</label>

                        <FormSelect
                          id="aplicationType"
                          :show-error="
                            $v.form.aplicationType.$error &&
                            !$v.form.aplicationType.required
                          "
                          v-model="form.aplicationType"
                          :items="aplicationTypes"
                          defaultOption="Elegir tipo de aplicación"
                        />

                        <form-error
                          message="Tipo de aplciación es requerido"
                          v-if="
                            $v.form.aplicationType.$error &&
                            !$v.form.aplicationType.required
                          "
                        ></form-error>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="period">Periodo *</label>

                        <FormInput
                          id="period"
                          type="text"
                          maxlength="20"
                          placeholder="Ingresar periodo"
                          :show-error="
                            $v.form.period.$error && !$v.form.period.required
                          "
                          v-model="form.period"
                        />

                        <form-error
                          message="Periodo es requerido"
                          v-if="
                            $v.form.period.$error && !$v.form.period.required
                          "
                        ></form-error>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="cycle">Ciclo *</label>

                        <FormSelect
                          id="cycle"
                          :show-error="
                            $v.form.cycle.$error && !$v.form.cycle.required
                          "
                          v-model="form.cycle"
                          :items="cyclesList"
                          defaultOption="Elegir ciclo"
                        />

                        <form-error
                          message="Ciclo es requerido"
                          v-if="$v.form.cycle.$error && !$v.form.cycle.required"
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="paymentCode">Código de Pago *</label>
                        <FormInput
                          id="paymentCode"
                          type="text"
                          maxlength="20"
                          placeholder="Ingresar código de Pago"
                          :show-error="
                            $v.form.paymentCode.$error &&
                            !$v.form.paymentCode.required
                          "
                          v-model="form.paymentCode"
                        />
                        <form-error
                          message="Código de Pago es requerido"
                          v-if="
                            $v.form.paymentCode.$error &&
                            !$v.form.paymentCode.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="zonales">Zonal</label>

                        <FormSelect
                          id="zonal"
                          :show-error="
                            $v.form.zonal.$error && !$v.form.zonal.required
                          "
                          v-model="form.zonal"
                          :items="zonalList"
                          defaultOption="Elegir zonal"
                        />

                        <form-error
                          message="Zonal es requerido"
                          v-if="$v.form.zonal.$error && !$v.form.zonal.required"
                        ></form-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section pb-0 border-0">
                  <h2 class="custom-card-subtitle mb-4">
                    Configuración de Clusters
                  </h2>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="cluster">Tipo de Cluster</label>

                        <FormSelect
                          id="cluster"
                          :show-error="
                            $v.form.cluster.$error && !$v.form.cluster.required
                          "
                          v-model="form.cluster"
                          :items="clusterList"
                          defaultOption="Elegir cluster"
                        />

                        <form-error
                          message="Cluster es requerido"
                          v-if="
                            $v.form.cluster.$error && !$v.form.cluster.required
                          "
                        ></form-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section border-0 pt-0">
                  <div class="form-group-buttons text-right">
                    <a
                      @click="$router.go(-1)"
                      class="button button-light"
                    >
                      {{ editing ? "Volver" : "Cancelar" }}
                    </a>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  Constants,
  FormError,
  FormInput,
  FormSelect
} from "wize-admin";

import { SchemeService } from "../../Core/Services";

import { required } from "vuelidate/lib/validators";

import { Constants as LocalConstants } from "@/core/utils";

import geoDataMixin from "@/core/Mixins/geoDataMixin";

export default {
  mixins: [geoDataMixin],
  data() {
    return {
      zonalList: [],
      showDatepicker: false,
      aplicationTypes: [
        LocalConstants.ApplicationTypes.PDV,
        LocalConstants.ApplicationTypes.PUSHERS,
        LocalConstants.ApplicationTypes.BOTH,
      ],
      cyclesList: [
        LocalConstants.CyclesList.MONTHLY,
        LocalConstants.CyclesList.FORTNIGHTLY,
        LocalConstants.CyclesList.TEN,
        LocalConstants.CyclesList.OTHERS,
      ],
      clusterList: [
        LocalConstants.ClusterList.DIAMOND,
        LocalConstants.ClusterList.TOP,
        LocalConstants.ClusterList.PLATINUM,
        LocalConstants.ClusterList.GOLD,
        LocalConstants.ClusterList.SILVER,
      ],
      form: {
        period: null,
        aplicationType: null,
        name: null,
        cycle: null,
        paymentCode: null,
        cluster: null,
        admissionDate: null,
        zonal: null,
      },
      editing: false,
    };
  },
  components: {
    FormError,
    FormSelect,
    FormInput
  },
  validations: {
    form: {
      aplicationType: { required },
      period: { required },
      name: { required },
      cycle: { required },
      paymentCode: { required },
      cluster: { required },
      zonal: { required },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  methods: {
    async save() {
      this.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      this.$store.dispatch("app/loading", true);
      const data = {};

      try {
        let response;
        if (!this.editing) {
          response = await SchemeService.save(data);
        } else {
          response = await SchemeService.update(this.$route.params.id, data);
        }

        if (response.status == "OK") {
          Alert.success("Se almacenó la información correctamente");
          if (!this.editing) {
            this.$router.push({
              name: "products-edit",
              params: { id: response.payload.id },
            });
            this.editing = true;
          }
        }
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await SchemeService.get(id);

        this.form = { ...response.payload }

        this.editing = true;
      } catch (ex) {
        console.error(ex);
        let message = ex.errors.message;
        if (message == "No encontrado")
          message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () =>
          this.$router.push({ name: "products-list" })
        );
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    }
  },
  async created() {
    if (this.$route.params.id) await this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus"></style>
