import { http } from "@/http";

export default {
  list(data, params) {
    return http.post("/celulares/zonal/list", data, { params });
  },
  save(data) {
    return http.post("/celulares/zonal", data);
  },
  get(id) {
    return http.get(`/celulares/zonal/${id}`);
  },
  changeStatus(id, status) {
    return http.put(`/celulares/zonal/${id}/status`, null, {
      params: { status },
    });
  },
};
