import RelationshipsView from "../RelationshipsView.vue";
import RelationshipsList from "../Pages/RelationshipsList.vue";
import RelationshipsEditor from "../Pages/RelationshipsEditor.vue";

const routes = [
  {
    path: 'relaciones-de-codigos',
    component: RelationshipsView,
    children: [
      {
        path: '',
        name: 'celulares-relationships-list',
        component: RelationshipsList
      },
      {
        path: 'crear',
        name: 'celulares-relationships-create',
        component: RelationshipsEditor
      },
      {
        path: ':id/editar',
        name: 'celulares-relationships-edit',
        component: RelationshipsEditor,
      }
    ]
  },
]

export default routes
