import { Util } from 'wize-admin'
import {http} from '@/http'

export default {
  list(data, params) {
    return http.post('/celulares/status/list', data, {params})
  },
  filterPaymentCodes(period, data, params) {
    return http.post(`/celulares/status/${period}/payment-codes`, data, {params})
  },
  filterPayments(period, paymentCode, data, params) {
    return http.post(`/celulares/status/${period}/payment-codes/${paymentCode}/payments`, data, {params})
  },
  download(data) {
    return http.post('/celulares/status/xlsx', data, {responseType: 'blob'})
  },
  downloadStatusDetail(period, data) {
    return http.post(`/celulares/status/${period}/payment-codes/xlsx`, data, {responseType: 'blob'})
  },
  downloadPaymentCodeDetailDetail(period, paymentCode, data) {
    return http.post(`/celulares/status/${period}/payment-codes/${paymentCode}/payments/xlsx`, data, {responseType: 'blob'})
  },
  changeStatusWithForm(id, data){
    const formData = Util.getFormData(data);
    return http.put(`/celulares/status/payment-item/${id}`, formData, {headers: { "Content-Type": "multipart/form-data" },})
  }
}
