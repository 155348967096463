import SchemeView from "../SchemeView.vue";
import SchemeList from "../Pages/SchemeList.vue";
import SchemeEditor from "../Pages/SchemeEditor.vue";

const routes = [
  {
    path: 'esquemas',
    component: SchemeView,
    children: [
      {
        path: '',
        name: 'celulares-schemes-list',
        component: SchemeList,
      },
      {
        path: 'crear',
        name: 'celulares-schemes-create',
        component: SchemeEditor
      }
    ]
  }
]

export default routes
