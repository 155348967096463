<template>
  <div class="page-container product-editor">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar vendedor" : "Nuevo vendedor" }}
      </h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12">
          <b-card
            tag="article"
            no-body
            style="max-width: 760px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{
                  editing ? "Datos del vendedor" : "Datos del nuevo vendedor"
                }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body px-0 pb-0">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section pt-0">
                  <h2 class="custom-card-subtitle mb-4">
                    Información personal
                  </h2>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group mb-0">
                        <label for="name">Nombre del colaborador *</label>

                        <FormInput
                          id="name"
                          type="text"
                          placeholder="Ingresar nombre del colaborador"
                          :show-error="
                            $v.form.name.$error && !$v.form.name.required
                          "
                          v-model="form.name"
                        />

                        <form-error
                          message="Nombre del colaborador es requerido"
                          v-if="$v.form.name.$error && !$v.form.name.required"
                        ></form-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section">
                  <h2 class="custom-card-subtitle mb-4">
                    Información de contacto
                  </h2>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="email"
                          >Correo electrónico corporativo *</label
                        >
                        <FormInput
                          id="email"
                          type="email"
                          :disabled="editing"
                          maxlength="100"
                          placeholder="Ingresar correo electrónico corporativo"
                          :show-error="
                            $v.form.email.$error && !$v.form.email.required
                          "
                          v-model="form.email"
                        />
                        <form-error
                          message="Correo electrónico corporativo es requerido"
                          v-if="$v.form.email.$error && !$v.form.email.required"
                        ></form-error>

                        <form-error
                          message="Ingrese un correo electrónico corporativo válido"
                          v-if="!$v.form.email.email && $v.form.email.$error"
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="phone">Número Celular Corporativo *</label>
                        <FormInput
                          id="phone"
                          type="text"
                          :disabled="editing"
                          maxlength="9"
                          placeholder="Ingresar número celular corporativo"
                          :show-error="
                            $v.form.phone.$error && !$v.form.phone.required
                          "
                          v-model="form.phone"
                        />
                        <form-error
                          message="Número celular corporativo es requerido"
                          v-if="$v.form.phone.$error && !$v.form.phone.required"
                        ></form-error>

                        <form-error
                          message="Ingrese un número válido"
                          v-if="!$v.form.phone.email && $v.form.phone.$error"
                        ></form-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section pb-0 border-0">
                  <h2 class="custom-card-subtitle mb-4">Información Laboral</h2>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group mb-0">
                        <label for="zonales">Zonales</label>

                        <b-form-group>
                          <b-form-checkbox-group
                            id="seller-zonal-checkbox-list"
                            v-model="form.zonal"
                            :options="zonalList"
                            name="zonales"
                          ></b-form-checkbox-group>
                        </b-form-group>

                        <form-error
                          message="Zonal es requerido"
                          v-if="$v.form.zonal.$error && !$v.form.zonal.required"
                        ></form-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section border-0 pt-0">
                  <div class="form-group-buttons text-right">
                    <a
                      @click="$router.go(-1)"
                      class="button button-light"
                    >
                      {{ editing ? "Volver" : "Cancelar" }}
                    </a>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Alert, Constants, FormError, FormInput } from "wize-admin";

import { ProductService } from "@/core/services";

import { required, email, numeric } from "vuelidate/lib/validators";

import geoDataMixin from "@/core/Mixins/geoDataMixin";

export default {
  mixins: [geoDataMixin],
  data() {
    return {
      zonalList: [],
      form: {
        name: null,
        email: null,
        phone: null,
        zonal: [],
      },
      editing: false,
    };
  },
  components: {
    FormError,
    FormInput
  },
  validations: {
    form: {
      name: { required },
      email: { required, email },
      phone: { required, numeric },
      channel: { required },
      supervisorType: { required },
      expenseLine: { required },
      area: { required },
      position: { required },
      zonal: { required },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  methods: {
    async save() {
      this.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      this.$store.dispatch("app/loading", true);
      const data = {};

      try {
        let response;
        if (!this.editing) {
          response = await ProductService.save(data);

          if (response.status == "OK") {
            // Eliminar variaciones anteriores
            this.variations = [];
          }
        } else {
          response = await ProductService.update(this.$route.params.id, data);
        }

        if (response.status == "OK") {
          Alert.success("Se almacenó la información correctamente");
          if (!this.editing) {
            this.$router.push({
              name: "products-edit",
              params: { id: response.payload.id },
            });
            this.editing = true;
          }
        }
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await ProductService.get(id);

        let data = {
          ...response.payload,
          assetIds: [],
        };

        this.product = JSON.parse(JSON.stringify(data));
        if (data.priceVariations) {
          const priceVariations = JSON.parse(data.priceVariations);
          if (priceVariations.VOLUME) {
            this.volumePrices = JSON.parse(priceVariations.VOLUME);
          }
        }

        this.product.supplierId = this.product.supplier
          ? this.product.supplier.id
          : null;
        this.editing = true;
      } catch (ex) {
        console.error(ex);
        let message = ex.errors.message;
        if (message == "No encontrado")
          message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () =>
          this.$router.push({ name: "products-list" })
        );
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    if (this.$route.params.id) await this.load(this.$route.params.id);

    await this.getZonalList()

    this.zonalList = this.zonalList.map((zonal) => {
      return {
        value: zonal.key,
        text: zonal.label
      };
    });
  },
};
</script>

<style lang="stylus">
@import "../Styles/seller-editor.styl"
</style>
