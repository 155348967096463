import { http } from "@/http";

export default {
  list(data, params) {
    return http.post("/celulares/clusters/list", data, { params });
  },
  active(params) {
    return http.get("/celulares/clusters/active", { params });
  },
  save(data) {
    return http.post("/celulares/clusters", data);
  },
  update(id, data) {
    return http.put(`/celulares/clusters/${id}`, data);
  },
  get(id) {
    return http.get(`/celulares/clusters/${id}`);
  },
  changeStatus(id, status) {
    return http.put(`/celulares/clusters/${id}/status`, null, {
      params: { status },
    });
  },
};
