var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v("Marcar como pagado N° "+_vm._s(_vm.paymentId))])]),_c('div',[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"640px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header d-flex align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v("Datos del pago")])]),_c('b-card-body',{staticClass:"custom-card-body p-0"},[_c('form',{staticClass:"custom-form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"form-section"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"paymentMethod"}},[_vm._v("Método de pago *")]),_c('FormSelect',{attrs:{"id":"paymentMethod","items":_vm.paymentMethods,"defaultOption":"Elegir método de pago","showError":_vm.$v.form.paymentMethod.$error &&
                      !_vm.$v.form.paymentMethod.required},model:{value:(_vm.form.paymentMethod),callback:function ($$v) {_vm.$set(_vm.form, "paymentMethod", $$v)},expression:"form.paymentMethod"}}),(
                      _vm.$v.form.paymentMethod.$error &&
                      !_vm.$v.form.paymentMethod.required
                    )?_c('FormError',{attrs:{"message":"Método de pago es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"paymentDate"}},[_vm._v("Fecha de abono *")]),_c('FormDatepicker',{staticClass:"custom-form-control p-0",staticStyle:{"border":"0"},attrs:{"label-id":"paymentDate","show-calendar":_vm.showPaymentDate,"max":new Date(),"hide-header":""},on:{"show":function($event){_vm.showPaymentDate = true},"hidden":function($event){_vm.showPaymentDate = false}},model:{value:(_vm.form.paymentDate),callback:function ($$v) {_vm.$set(_vm.form, "paymentDate", $$v)},expression:"form.paymentDate"}}),(
                      _vm.$v.form.paymentDate.$error &&
                      !_vm.$v.form.paymentDate.required
                    )?_c('FormError',{attrs:{"message":"Fecha de abono es requerido"}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"operationNumber"}},[_vm._v("Número de operación "+_vm._s(_vm.form.paymentMethod === _vm.paymentMethodsList.BANK_TRANSFER.key ? "*" : ""))]),_c('FormInput',{attrs:{"id":"operationNumber","type":"text","placeholder":"Ingresar número de operación"},model:{value:(_vm.form.operationNumber),callback:function ($$v) {_vm.$set(_vm.form, "operationNumber", $$v)},expression:"form.operationNumber"}})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"payedAmount"}},[_vm._v("Monto de abono *")]),_c('FormInput',{attrs:{"id":"payedAmount","type":"number","min":"0","placeholder":"Ingresar monto de abono","show-error":_vm.$v.form.payedAmount.$error &&
                      !_vm.$v.form.payedAmount.required},model:{value:(_vm.form.payedAmount),callback:function ($$v) {_vm.$set(_vm.form, "payedAmount", $$v)},expression:"form.payedAmount"}}),(
                      _vm.$v.form.payedAmount.$error &&
                      !_vm.$v.form.payedAmount.required
                    )?_c('FormError',{attrs:{"message":"Monto de abono es requerido"}}):_vm._e()],1)])]),(
                _vm.form.paymentMethod === _vm.paymentMethodsList.YAPE.key ||
                _vm.form.paymentMethod === _vm.paymentMethodsList.PLIN.key
              )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"paymentMobileOrigin"}},[_vm._v("Celular de origen *")]),_c('FormInput',{attrs:{"id":"paymentMobileOrigin","type":"text","placeholder":"Ingresar celular de origen","show-error":_vm.$v.form.paymentMobileOrigin.$error &&
                      !_vm.$v.form.paymentMobileOrigin.required},model:{value:(_vm.form.paymentMobileOrigin),callback:function ($$v) {_vm.$set(_vm.form, "paymentMobileOrigin", $$v)},expression:"form.paymentMobileOrigin"}}),(
                      _vm.$v.form.paymentMobileOrigin.$error &&
                      !_vm.$v.form.paymentMobileOrigin.required
                    )?_c('FormError',{attrs:{"message":"Celular de origen es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"paymentMobileDestination"}},[_vm._v("Celular de destino *")]),_c('FormInput',{attrs:{"id":"paymentMobileDestination","type":"text","placeholder":"Ingresar celular de destino","show-error":_vm.$v.form.paymentMobileDestination.$error &&
                      !_vm.$v.form.paymentMobileDestination.required},model:{value:(_vm.form.paymentMobileDestination),callback:function ($$v) {_vm.$set(_vm.form, "paymentMobileDestination", $$v)},expression:"form.paymentMobileDestination"}}),(
                      _vm.$v.form.paymentMobileDestination.$error &&
                      !_vm.$v.form.paymentMobileDestination.required
                    )?_c('FormError',{attrs:{"message":"Celular de destino es requerido"}}):_vm._e()],1)])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group mb-0"},[_c('label',{attrs:{"for":"records"}},[_vm._v("Cargar constancias")]),_c('FileInput',{staticClass:"w-100 mt-2",attrs:{"multiple":true,"id-file":"records","acceptFile":['.pdf']},on:{"onChange":_vm.onRecordsChange}}),(
                      _vm.$v.form.records.$error &&
                      !_vm.$v.form.records.required
                    )?_c('FormError',{attrs:{"message":"Constancias son requeridas"}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group-buttons text-right"},[_c('router-link',{staticClass:"button button-light",attrs:{"to":{ name: 'celulares-status-details-payment-code', params: {periodId: _vm.periodId, paymentCode: _vm.paymentCode} }}},[_vm._v(" Volver ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])],1)])])])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }