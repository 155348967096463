import { http } from "@/http";

export default {
  list(data, params) {
    return http.post("/celulares/types/list", data, { params });
  },
  active(data, params) {
    return http.get("/celulares/types/active",{ params });
  },
  save(data) {
    return http.post("/celulares/types", data);
  },
  update(id, data) {
    return http.put(`/celulares/types/${id}`, data);
  },
  get(id) {
    return http.get(`/celulares/types/${id}`);
  },
  changeStatus(id, status) {
    return http.put(`/celulares/types/${id}/status`, null, {
      params: { status },
    });
  },
};
