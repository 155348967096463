<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Marcar como pagado N° {{ paymentId }}</h1>
    </div>
    <div>
      <b-card
        tag="article"
        no-body
        style="max-width: 640px"
        class="custom-card"
      >
        <b-card-header
          header-tag="section"
          class="custom-card-header d-flex align-items-center"
        >
          <h3>Datos del pago</h3>
        </b-card-header>
        <b-card-body class="custom-card-body p-0">
          <form class="custom-form" @submit.prevent="save" novalidate>
            <div class="form-section">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="paymentMethod" class="label"
                      >Método de pago *</label
                    >

                    <FormSelect
                      id="paymentMethod"
                      v-model="form.paymentMethod"
                      :items="paymentMethods"
                      defaultOption="Elegir método de pago"
                      :showError="
                        $v.form.paymentMethod.$error &&
                        !$v.form.paymentMethod.required
                      "
                    />

                    <FormError
                      v-if="
                        $v.form.paymentMethod.$error &&
                        !$v.form.paymentMethod.required
                      "
                      message="Método de pago es requerido"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="paymentDate" class="label"
                      >Fecha de abono *</label
                    >
                    <FormDatepicker
                      label-id="paymentDate"
                      :show-calendar="showPaymentDate"
                      style="border: 0"
                      class="custom-form-control p-0"
                      :max="new Date()"
                      hide-header
                      @show="showPaymentDate = true"
                      @hidden="showPaymentDate = false"
                      v-model="form.paymentDate"
                    />

                    <FormError
                      v-if="
                        $v.form.paymentDate.$error &&
                        !$v.form.paymentDate.required
                      "
                      message="Fecha de abono es requerido"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="operationNumber" class="label"
                      >Número de operación
                      {{
                        form.paymentMethod ===
                        paymentMethodsList.BANK_TRANSFER.key
                          ? "*"
                          : ""
                      }}</label
                    >

                    <FormInput
                      id="operationNumber"
                      type="text"
                      placeholder="Ingresar número de operación"
                      v-model="form.operationNumber"
                    />

                    <!-- <FormError
                      v-if="
                        $v.form.operationNumber.$error &&
                        !$v.form.operationNumber.required
                      "
                      message="Número de operación es requerido"
                    /> -->
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="payedAmount" class="label"
                      >Monto de abono *</label
                    >

                    <FormInput
                      id="payedAmount"
                      type="number"
                      min="0"
                      placeholder="Ingresar monto de abono"
                      :show-error="
                        $v.form.payedAmount.$error &&
                        !$v.form.payedAmount.required
                      "
                      v-model="form.payedAmount"
                    />

                    <FormError
                      v-if="
                        $v.form.payedAmount.$error &&
                        !$v.form.payedAmount.required
                      "
                      message="Monto de abono es requerido"
                    />
                  </div>
                </div>
              </div>

              <!-- <div
                class="row"
                v-if="
                  form.paymentMethod === paymentMethodsList.BANK_TRANSFER.key
                "
              >
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="sourceAccount" class="label"
                      >Cuenta de origen *</label
                    >
                    <FormSelect
                      id="sourceAccount"
                      v-model="form.sourceAccount"
                      :items="sourceAccounts"
                      defaultOption="Elegir cuenta de origen"
                      :showError="
                        $v.form.sourceAccount.$error &&
                        !$v.form.sourceAccount.required
                      "
                    />

                    <FormError
                      v-if="
                        $v.form.sourceAccount.$error &&
                        !$v.form.sourceAccount.required
                      "
                      message="Cuenta de origen es requerida"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="depositBank" class="label"
                      >Banco de abono *</label
                    >

                    <FormSelect
                      id="depositBank"
                      v-model="form.depositBank"
                      :items="bankList"
                      defaultOption="Elegir banco de abono"
                      :showError="
                        $v.form.depositBank.$error &&
                        !$v.form.depositBank.required
                      "
                    />

                    <FormError
                      v-if="
                        $v.form.sourceAccount.$error &&
                        !$v.form.sourceAccount.required
                      "
                      message="Banco de abono es requerido"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="depositAccount" class="label"
                      >Cuenta de abono *</label
                    >

                    <FormInput
                      id="depositAccount"
                      type="text"
                      placeholder="Ingresar cuenta de abono"
                      :show-error="
                        $v.form.depositAccount.$error &&
                        !$v.form.depositAccount.required
                      "
                      v-model="form.depositAccount"
                    />

                    <FormError
                      v-if="
                        $v.form.depositAccount.$error &&
                        !$v.form.depositAccount.required
                      "
                      message="Cuenta de abono es requerida"
                    />
                  </div>
                </div>
              </div> -->

              <div
                class="row"
                v-if="
                  form.paymentMethod === paymentMethodsList.YAPE.key ||
                  form.paymentMethod === paymentMethodsList.PLIN.key
                "
              >
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="paymentMobileOrigin" class="label"
                      >Celular de origen *</label
                    >

                    <FormInput
                      id="paymentMobileOrigin"
                      type="text"
                      placeholder="Ingresar celular de origen"
                      :show-error="
                        $v.form.paymentMobileOrigin.$error &&
                        !$v.form.paymentMobileOrigin.required
                      "
                      v-model="form.paymentMobileOrigin"
                    />

                    <FormError
                      v-if="
                        $v.form.paymentMobileOrigin.$error &&
                        !$v.form.paymentMobileOrigin.required
                      "
                      message="Celular de origen es requerido"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="paymentMobileDestination" class="label"
                      >Celular de destino *</label
                    >

                    <FormInput
                      id="paymentMobileDestination"
                      type="text"
                      placeholder="Ingresar celular de destino"
                      :show-error="
                        $v.form.paymentMobileDestination.$error &&
                        !$v.form.paymentMobileDestination.required
                      "
                      v-model="form.paymentMobileDestination"
                    />

                    <FormError
                      v-if="
                        $v.form.paymentMobileDestination.$error &&
                        !$v.form.paymentMobileDestination.required
                      "
                      message="Celular de destino es requerido"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="form-group mb-0">
                    <label for="records">Cargar constancias</label>
                    <FileInput
                      class="w-100 mt-2"
                      :multiple="true"
                      id-file="records"
                      :acceptFile="['.pdf']"
                      @onChange="onRecordsChange"
                    />

                    <FormError
                      v-if="
                        $v.form.records.$error &&
                        !$v.form.records.required
                      "
                      message="Constancias son requeridas"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="form-group-buttons text-right">
                    <router-link
                      :to="{ name: 'celulares-status-details-payment-code', params: {periodId, paymentCode} }"
                      class="button button-light"
                    >
                      Volver
                    </router-link>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  FileInput,
  FormDatepicker,
  FormError,
  FormInput,
  FormSelect,
} from "wize-admin";

// import * as moment from "moment";

import { Constants as LocalConstants } from "@/core/utils";

import { StatusService } from "../../Core/Services";
import { required, requiredIf, minLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      periodId: this.$route.params.periodId,
      paymentCode: this.$route.params.paymentCode,
      paymentId: this.$route.params.paymentId,
      paymentData: null,
      paymentMethodsList: LocalConstants.PaymentMethods,
      paymentMethods: [
        // LocalConstants.PaymentMethods.BANK_TRANSFER,
        LocalConstants.PaymentMethods.YAPE,
        LocalConstants.PaymentMethods.PLIN,
      ],
      sourceAccounts: [
        { key: "0011-0349-00-0100-014171", label: "0011-0349-00-0100-014171" },
        { key: "0011-0349-80-0100-038224", label: "0011-0349-80-0100-038224" },
      ],
      bankList: [],
      showPaymentDate: false,
      form: {
        paymentMethod: null,
        paymentDate: null,
        operationNumber: null,
        sourceAccount: null,
        depositBank: null,
        depositAccount: null,
        payedAmount: null,
        syncDeposit: null,
        records: [],
        paymentMobileOrigin: null,
        paymentMobileDestination: null
      },
    };
  },
  components: {
    FormSelect,
    FormDatepicker,
    FormInput,
    FileInput,
    FormError,
  },
  validations: {
    form: {
      paymentMethod: { required },
      paymentDate: { required },
      // operationNumber: {
      //   required: requiredIf(function () {
      //     return (
      //       this.form.paymentMethod ===
      //       LocalConstants.PaymentMethods.BANK_TRANSFER.key
      //     );
      //   }),
      // },
      sourceAccount: {
        required: requiredIf(function () {
          return (
            this.form.paymentMethod ===
            LocalConstants.PaymentMethods.BANK_TRANSFER.key
          );
        }),
      },
      depositBank: {
        required: requiredIf(function () {
          return (
            this.form.paymentMethod ===
            LocalConstants.PaymentMethods.BANK_TRANSFER.key
          );
        }),
      },
      depositAccount: {
        required: requiredIf(function () {
          return (
            this.form.paymentMethod ===
            LocalConstants.PaymentMethods.BANK_TRANSFER.key
          );
        }),
      },
      paymentMobileOrigin: {
        required: requiredIf(function () {
          return (
            this.form.paymentMethod ===
              LocalConstants.PaymentMethods.PLIN.key ||
            this.form.paymentMethod === LocalConstants.PaymentMethods.YAPE.key
          );
        }),
      },
      paymentMobileDestination: {
        required: requiredIf(function () {
          return (
            this.form.paymentMethod ===
              LocalConstants.PaymentMethods.PLIN.key ||
            this.form.paymentMethod === LocalConstants.PaymentMethods.YAPE.key
          );
        }),
      },
      payedAmount: { required },
      records: { required, minLength: minLength(1) }
    },
  },
  computed: {},
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;
      this.$store.dispatch("app/loading", true);

      try {
        const data = {
          ...this.form
        };

        let response = await StatusService.changeStatusWithForm(
          this.paymentId,
          data
        );
        if (response.payload) Alert.success("Estado actualizado correctamente");

        Alert.success("Se almacenó la información correctamente");
        this.$router.go(-1)
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    onRecordsChange(files) {
      if (Array.isArray(files)) {
        this.form.records = files;
      } else {
        this.form.records = [files];
      }
    },
  },
};
</script>

<style lang="stylus" scoped></style>
