import {http} from '@/http'

export default {
  list(data, params) {
    return http.post('/celulares/pdv/list', data, { params })
  },
  save(data) {
    return http.post('/celulares/pdv', data)
  },
  update(id, data){
    return http.put(`/celulares/pdv/${id}`, data)
  },
  changeStatus(id, status){
    return http.put(`/celulares/pdv/${id}/status`, null, {params: {status}})
  },
  get(id){
    return http.get(`/celulares/pdv/${id}`)
  },
  queryDni(dni) {
    return http.get(`/celulares/pdv/dni/${dni}`)
  },
  download(data) {
    return http.post('/celulares/pdv/xlsx', data, {responseType: 'blob'})
  }
}
