<template>
  <div>
    <div :class="`wrapper-page${isMenuOpen ? '' : '-closeSidebar'}`">
      <!-- Mobile -->
      <PageSidebarMobile :show="isMenuOpen && currentDevice != 'desktop'" />

      <!-- Desktop -->
      <page-sidebar
        :isMenuOpen="isMenuOpen"
        :tooltipType="tooltipType"
        @toggleMenu="toggleMenu"
        v-if="currentDevice == 'desktop'"
      >
        <template #custom-header>
          <img
            src="../../../../core/assets/images/layout/logo-admin-2.png"
            alt="Mirana"
            class="sidebar-logo"
          />
          <span class="sidebar-title w-50">MIRANA</span>
        </template>

        <template #list-items>
          <router-link
            :to="{ name: 'celulares-dashboard' }"
            tag="li"
            v-if="$can('manage', 'celulares-dashboard')"
            exact-active-class="active"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'home-item'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <span class="menu-icon">
                <i class="ri-home-5-line"></i>
              </span>

              <span class="sidebar-item-text">Portada</span>

              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'home-item'"
                text="Portada"
              />
            </div>
          </router-link>

          <!-- Status de pago -->
          <router-link
            tag="li"
            event=""
            exact-active-class="active"
            :to="{name: 'celulares-status'}"
            class="sidebar-item d-flex align-items-center"
            @click.native.prevent="
              currentSubmenu === 'status'
                ? setCurrentSubmenu(null)
                : setCurrentSubmenu('status')
            "
          >
            <div
              class="d-flex flex-column justify-content-center sidebar-item-container"
            >
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex"
                  @mouseover="tooltipType = 'status-item'"
                  @mouseleave="tooltipType = null"
                >
                  <span class="menu-icon">
                    <i class="ri-wallet-2-line"></i>
                  </span>

                  <span class="sidebar-item-text">Status de pago</span>

                  <CustomTooltip
                    custom-class="tooltip-sidebar"
                    v-if="!isMenuOpen && tooltipType === 'status-item'"
                    text="Status de pago"
                  />
                </div>
              </div>
              <span
                :class="`sidebar-item-description ${
                  isCurrent('status') ? 'active' : ''
                }`"
              >
                Lista, Carga base
              </span>
            </div>
            <div v-if="isMenuOpen">
              <span v-if="isCurrent('status')">
                <i class="ri-arrow-up-s-line"></i>
              </span>
              <span v-else>
                <i class="ri-arrow-down-s-line"></i>
              </span>
            </div>
          </router-link>

          <!-- Submenu status de pago -->
          <section>
            <ul
              :class="`sidebar-submenu ${
                isCurrent('status') ? 'active' : ''
              } pl-0`"
            >
            <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="celulares-status"
                text="Lista"
              ></sub-item>
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="celulares-base-batch"
                text="Cargar base"
              ></sub-item>
            </ul>
          </section>

          <!-- Mantenimiento -->
          <router-link
            event=""
            tag="li"
            exact-active-class="active"
            :to="{name: 'celulares-config-cluster-list'}"
            class="sidebar-item d-flex align-items-center"
            @click.native.prevent="
              currentSubmenu === 'config'
                ? setCurrentSubmenu(null)
                : setCurrentSubmenu('config')
            "
          >
            <div
              class="d-flex flex-column justify-content-center sidebar-item-container"
            >
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex"
                  @mouseover="tooltipType = 'config-item'"
                  @mouseleave="tooltipType = null"
                >
                  <span class="menu-icon">
                    <i class="ri-edit-line"></i>
                  </span>

                  <span class="sidebar-item-text">Mantenimiento</span>

                  <CustomTooltip
                    custom-class="tooltip-sidebar"
                    v-if="!isMenuOpen && tooltipType === 'config-item'"
                    text="Mantenimiento"
                  />
                </div>
              </div>
              <span
                :class="`sidebar-item-description ${
                  isCurrent('config') ? 'active' : ''
                }`"
              >
                Jefes de ventas, Supervisores, Vendedores
              </span>
            </div>
            <div v-if="isMenuOpen">
              <span v-if="isCurrent('config')">
                <i class="ri-arrow-up-s-line"></i>
              </span>
              <span v-else>
                <i class="ri-arrow-down-s-line"></i>
              </span>
            </div>
          </router-link>

          <!-- Submenu mantenimiento -->
          <section>
            <ul
              :class="`sidebar-submenu ${
                isCurrent('config') ? 'active' : ''
              } pl-0`"
            >
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="celulares-config-cluster-list"
                text="Clústers"
              ></sub-item>

              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="celulares-config-zonal-list"
                text="Zonales"
              ></sub-item>

              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="celulares-config-types-list"
                text="Tipos"
              ></sub-item>
            </ul>
          </section>

          <!-- Cargos -->
          <router-link
            event=""
            tag="li"
            exact-active-class="active"
            :to="{name: 'celulares-managers-list'}"
            class="sidebar-item d-flex align-items-center"
            @click.native.prevent="
              currentSubmenu === 'roles'
                ? setCurrentSubmenu(null)
                : setCurrentSubmenu('roles')
            "
          >
            <div
              class="d-flex flex-column justify-content-center sidebar-item-container"
            >
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex"
                  @mouseover="tooltipType = 'roles-item'"
                  @mouseleave="tooltipType = null"
                >
                  <span class="menu-icon">
                    <i class="ri-government-line"></i>
                  </span>

                  <span class="sidebar-item-text">Cargos</span>

                  <CustomTooltip
                    custom-class="tooltip-sidebar"
                    v-if="!isMenuOpen && tooltipType === 'roles-item'"
                    text="Cargos"
                  />
                </div>
              </div>
              <span
                :class="`sidebar-item-description ${
                  isCurrent('roles') ? 'active' : ''
                }`"
              >
                Jefes de ventas, Supervisores, Vendedores
              </span>
            </div>
            <div v-if="isMenuOpen">
              <span v-if="isCurrent('roles')">
                <i class="ri-arrow-up-s-line"></i>
              </span>
              <span v-else>
                <i class="ri-arrow-down-s-line"></i>
              </span>
            </div>
          </router-link>

          <!-- Submenu cargos -->
          <section>
            <ul
              :class="`sidebar-submenu ${
                isCurrent('roles') ? 'active' : ''
              } pl-0`"
            >
            <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="celulares-managers-list"
                text="Jefes de ventas"
              ></sub-item>

              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="celulares-supervisors-list"
                text="Supervisores"
              ></sub-item>

              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="celulares-sellers-list"
                text="Vendedores"
              ></sub-item>
            </ul>
          </section>

          <!-- Puntos de venta -->
          <router-link
            event=""
            tag="li"
            active-class="active"
            :to="{name: 'celulares-sales-points-list'}"
            class="sidebar-item d-flex align-items-center"
            @click.native.prevent="
              currentSubmenu === 'sales-points'
                ? setCurrentSubmenu(null)
                : setCurrentSubmenu('sales-points')
            "
          >
            <div
              class="d-flex flex-column justify-content-center sidebar-item-container"
            >
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex"
                  @mouseover="tooltipType = 'sales-points-item'"
                  @mouseleave="tooltipType = null"
                >
                  <span class="menu-icon">
                    <i class="ri-store-line"></i>
                  </span>

                  <span class="sidebar-item-text">Puntos de venta</span>

                  <CustomTooltip
                    custom-class="tooltip-sidebar"
                    v-if="!isMenuOpen && tooltipType === 'sales-points-item'"
                    text="Puntos de venta"
                  />
                </div>
              </div>
              <span
                :class="`sidebar-item-description ${
                  isCurrent('sales-points') ? 'active' : ''
                }`"
              >
                Lista, Carga masiva
              </span>
            </div>
            <div v-if="isMenuOpen">
              <span v-if="isCurrent('sales-points')">
                <i class="ri-arrow-up-s-line"></i>
              </span>
              <span v-else>
                <i class="ri-arrow-down-s-line"></i>
              </span>
            </div>
          </router-link>

          <!-- Submenu Puntos de venta -->
          <section>
            <ul
              :class="`sidebar-submenu ${
                isCurrent('sales-points') ? 'active' : ''
              } pl-0`"
            >
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="celulares-sales-points-list"
                text="Lista"
              ></sub-item>

              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="celulares-sales-points-upload"
                text="Carga masiva"
              ></sub-item>
            </ul>
          </section>

          <!-- Impulsadores -->
          <router-link
            event=""
            tag="li"
            active-class="active"
            :to="{name: 'celulares-pushers-list'}"
            class="sidebar-item d-flex align-items-center"
            @click.native.prevent="
              currentSubmenu === 'booster'
                ? setCurrentSubmenu(null)
                : setCurrentSubmenu('booster')
            "
          >
            <div
              class="d-flex flex-column justify-content-center sidebar-item-container"
            >
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex"
                  @mouseover="tooltipType = 'booster-item'"
                  @mouseleave="tooltipType = null"
                >
                  <span class="menu-icon">
                    <i class="ri-user-star-line"></i>
                  </span>

                  <span class="sidebar-item-text">Impulsadores</span>

                  <CustomTooltip
                    custom-class="tooltip-sidebar"
                    v-if="!isMenuOpen && tooltipType === 'booster-item'"
                    text="Impulsadores"
                  />
                </div>
              </div>
              <span
                :class="`sidebar-item-description ${
                  isCurrent('booster') ? 'active' : ''
                }`"
              >
              Lista, Carga masiva
              </span>
            </div>
            <div v-if="isMenuOpen">
              <span v-if="isCurrent('booster')">
                <i class="ri-arrow-up-s-line"></i>
              </span>
              <span v-else>
                <i class="ri-arrow-down-s-line"></i>
              </span>
            </div>
          </router-link>

          <!-- Submenu Impulsadores -->
          <section>
            <ul
              :class="`sidebar-submenu ${
                isCurrent('booster') ? 'active' : ''
              } pl-0`"
            >
              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="celulares-pushers-list"
                text="Lista"
              ></sub-item>

              <sub-item
                :isMenuOpen="isMenuOpen"
                routeName="celulares-pushers-upload"
                text="Carga masiva"
              ></sub-item>
            </ul>
          </section>

          <router-link
            :to="{ name: 'celulares-relationships-list' }"
            tag="li"

            exact-active-class="active"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'deliverables-item'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <span class="menu-icon">
                <i class="ri-article-line"></i>
              </span>

              <span class="sidebar-item-text">Relaciones de códigos</span>

              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'deliverables-item'"
                text="Relaciones de códigos"
              />
            </div>
          </router-link>

          <router-link
            :to="{ name: 'celulares-schemes-list' }"
            tag="li"
            exact-active-class="active"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'scheme-item'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <span class="menu-icon">
                <i class="ri-donut-chart-line"></i>
              </span>

              <span class="sidebar-item-text">Esquemas</span>

              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'scheme-item'"
                text="Esquemas"
              />
            </div>
          </router-link>

          <!-- <router-link
            :to="{ name: 'celulares-accelators-list' }"
            tag="li"
            exact-active-class="active"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'acelerators-item'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <span class="menu-icon">
                <i class="ri-bubble-chart-line"></i>
              </span>

              <span class="sidebar-item-text">Aceleradores</span>

              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'acelerators-item'"
                text="Aceleradores"
              />
            </div>
          </router-link> -->

          <router-link
            :to="{ name: 'celulares-ad-audit-log' }"
            tag="li"
            exact-active-class="active"
            class="d-flex sidebar-item"
            @mouseover.native="tooltipType = 'audit-log'"
            @mouseleave.native="tooltipType = null"
          >
            <div class="d-flex align-items-center">
              <span class="menu-icon">
                <i class="ri-edit-line"></i>
              </span>

              <span class="sidebar-item-text">Auditoría</span>
              <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'audit-log'"
                text="Auditoría"
              />
            </div>
          </router-link>
        </template>
      </page-sidebar>

      <div class="wrapper-page__content">
        <page-header />
        <div class="wrapper-page__inner">
          <b-container fluid>
            <slot></slot>
          </b-container>
        </div>
      </div>

      <page-footer />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import { PageSidebar, CustomTooltip, PageFooter, SubItem } from "wize-admin";

import PageHeader from "./PageHeader.vue";
import PageSidebarMobile from "./layout/PageSidebarMobile";

export default {
  name: "PageBase",
  components: {
    PageHeader,
    PageSidebar,
    PageFooter,
    CustomTooltip,
    PageSidebarMobile,
    SubItem
  },
  data() {
    return {
      matchMediaMd: window.matchMedia("(max-width: 768px)"),
      tooltipType: null,
    };
  },
  mounted() {
    this.calcResize();
  },
  computed: {
    ...mapGetters({
      currentSubmenu: "app/getCurrentSubmenu",
      isMenuOpen: "app/isMenuOpen",
      currentDevice: "app/getCurrentDevice",
    }),
  },
  methods: {
    ...mapMutations({
      setCurrentSubmenu: "app/setCurrentSubmenu",
      setMenu: "app/setMenu",
      toggleMenu: "app/toggleMenu",
    }),
    setCurrentDevice() {
      if (screen.width < 992) {
        this.$store.commit("app/setCurrentDevice", "mobile");
        this.toggleMenu(false);
      } else {
        this.$store.commit("app/setCurrentDevice", "desktop");
        this.toggleMenu(true);
      }
    },
    calcResize() {
      this.setCurrentDevice();

      this.lastWidth = window.innerWidth;

      window.addEventListener("resize", () => {
        const currentWidth = window.innerWidth;

        if (currentWidth !== this.lastWidth) {
          this.setCurrentDevice();
          this.lastWidth = currentWidth;
        }
      });
    },
    isMatchMedia() {
      if (this.matchMediaMd.matches) {
        this.setMenu(false);
      } else {
        this.setMenu(true);
      }
    },
    isCurrent(submenu) {
      return this.currentSubmenu.includes(submenu);
    },
  },
  created() {
    this.matchMediaMd.addListener(this.isMatchMedia);
  },
};
</script>

<style scoped lang="stylus"></style>
