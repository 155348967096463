<template>
  <div class="page-container pushers-list">
    <div class="page-title-container">
      <h1 class="page-title">Tipos</h1>
    </div>
    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <button
                type="button"
                class="filter d-flex align-items-center justify-content-between border-0"
                @click="showFilters = !showFilters"
              >
                <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                <div>Filtrar</div>
              </button>

              <div class="d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por descripción"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />

              <div class="text-right mt-2 mt-md-0">
                <b-button
                  :to="{ name: 'celulares-config-types-create' }"
                  variant="none"
                  class="button button-primary ml-md-2"
                  >Agregar</b-button
                >
              </div>
            </div>
          </section>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filterList"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="text-white p-0 bg-transparent border-0 ml-2"
              @click="deleteFilter(name)"
            >
              <i class="ri-close-circle-line"></i>
            </button>
          </span>
        </div>
      </div>

      <div>
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Segmento</span>
            </div>

            <div>
              <span>Descripción</span>
            </div>

            <div>
              <span>Estado</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span>{{ o.segment }} </span>
                </div>

                <div>
                  <span>{{ o.description }} </span>
                </div>

                <div>
                  <span :class="`badge ${getStatus(o.status).color}`">{{
                      getStatus(o.status).label
                    }}</span>
                </div>
              </div>

              <div class="custom-table-mobile-options mt-3">
                <router-link
                  :to="{
                    name: 'celulares-config-types-edit',
                    params: { id: o.id },
                  }"
                  class="mr-2"
                >
                  Editar
                </router-link>

                <a
                  href=""
                  @click.prevent="
                    openChangeStatus(o)
                  "
                  class="mr-2"
                  >{{ getStatus(o.status).action }}</a
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th class="pr-2">Segmento</th>
              <th class="pr-2">Descripción</th>
              <th>Estado</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="text-center">{{ getSegment(o.segment).label }}</td>
              <td class="text-center">{{ o.description }}</td>
              <td class="cell-center pl-md-2">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>
              <td class="cell-center d_option pr-md-2 position-relative">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Editar"
                      :to="{
                        name: 'celulares-config-types-edit',
                        params: { id: o.id },
                      }"
                    />
                    <DropdownItem
                      :text="getStatus(o.status).action"
                      @click="openChangeStatus(o)"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
          v-if="list.length"
        />

        <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>

    <ConfirmationModal
      :show="confirmationModal.show"
      @hide="closeConfirmationModal"
      :data="confirmationModal"
      @confirm="confirmModal"
    />
  </div>
</template>

<script>
import {
  Constants,
  Util,
  Alert,
  PaginationMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
  FilterMenu,
  FilterMixin,
  ConfirmationModalMixin,
  ConfirmationModal
} from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";


import { TypeService } from "@/core/services"

export default {
  components: {
    SearchBar,
    DropdownTable,
    DropdownItem,
    FilterMenu,
    ConfirmationModal
  },
  data() {
    return {
      list: [],
      ClusterStatus: LocalConstants.ClusterStatus,
      tooltipType: null,
      filterOptions: [
        {
          type: Constants.TypeFilters.SINGLE,
          label: 'Estado',
          key: 'status',
          options: [
            LocalConstants.ClusterStatus.ACTIVE,
            LocalConstants.ClusterStatus.INACTIVE
          ]
        }
      ],
    };
  },
  mixins: [PaginationMixin, FilterMixin, ConfirmationModalMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "id",
        };

        const data = { search: this.search, ...this.filters };
        const response = await TypeService.list(data, params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.ClusterStatus);
    },
    getSegment(key) {
      return Util.searchValue(key, LocalConstants.SegmentType);
    },
    openChangeStatus(o) {
      this.selectedOption = o;
      this.openConfirmationModal({
        title: "Cambiar estado",
        description: "¿Está seguro que desea cambiar el estado?",
        acceptText: "Aceptar",
        action: "update",
      });
    },
    confirmModal() {
      this.closeConfirmationModal();
      if (this.confirmationModal.action === "update") this.changeStatus();
    },
    async changeStatus() {
      try {
        await this.$store.dispatch("app/loading", true);

        let status = this.selectedOption.status;

        if (status === LocalConstants.TypesStatus.ACTIVE.key) {
          status = LocalConstants.TypesStatus.INACTIVE.key;
        } else if (status === LocalConstants.TypesStatus.INACTIVE.key) {
          status = LocalConstants.TypesStatus.ACTIVE.key;
        }

        await TypeService.changeStatus(this.selectedOption.id, status);
        await this.loadData();
        Alert.success("Estado actualizado correctamente");
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    }
  },
  async created() {
    await this.loadData();
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/pushers-list';
</style>
