import { UbigeoService, ZonalService, ClusterService, TypeService, EmployeeService } from "@/core/services"

export default {
  methods: {
    async getUbigeos() {
      const districts = (await UbigeoService.listDistricts()).payload;

      districts.forEach((b) => {
        if(this.districtList) {
          this.districtList.push({ key: b.id, label: b.description })
        }
      });
    },
    async getZonalList() {
      const zonalList = (await ZonalService.listActive()).payload;

      if(this.zonalList) {
        zonalList.forEach((b) => this.zonalList.push({ key: b.id, label: b.description }));
      }
    },
    async getClusterList() {
      const clusterList = (await ClusterService.active()).payload;

      if(this.clusterList) {
        clusterList.forEach((b) => this.clusterList.push({ key: b.id, label: b.description }));
      }
    },
    async getTypeList(segment) {
      const typeList = (await TypeService.active({}, {segment})).payload;

      if(this.typeList) {
        typeList.forEach((b) => this.typeList.push({ key: b.id, label: b.description }));
      }
    },
    async getSellerList() {
      const sellerList = (await EmployeeService.listActive()).payload;

      if(this.sellerList) {
        sellerList.forEach((b) => this.sellerList.push({ key: b.id, label: b.label }));
      }
    }
  },
}
