export const generatePeriods = (months = 12) => {
  const periods = [];
  const today = new Date(); // new Date(); // Use this line to get the current date

  // Check if today is the last day of the month
  const isLastDayOfMonth = today.getDate() === new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
  console.log(today)
  // Start from this month
  let current = new Date(today.getFullYear(), today.getMonth(), 1);

  // Add the last 12 months
  for (let i = 0; i < months; i++) {
    const yyyymm = current.getFullYear().toString() + String(current.getMonth() + 1).padStart(2, '0');
    periods.push(yyyymm); // unshift to build from oldest to newest
    current.setMonth(current.getMonth() - 1);
  }

  // If it's the last day of the month, add the next month
  if (isLastDayOfMonth) {
    const next = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const yyyymm = next.getFullYear().toString() + String(next.getMonth() + 1).padStart(2, '0');
    periods.unshift(yyyymm);
  }

  return periods;
};


export default {
  generatePeriods
}
