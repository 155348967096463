import DeliverablesView from "../DeliverablesView.vue";
import DeliverableList from "../Pages/DeliverableList.vue";
import DeliverableEditor from "../Pages/DeliverableEditor.vue";

const routes = [
  {
    path: 'entregables',
    component: DeliverablesView,
    children: [
      {
        path: '',
        name: 'celulares-deliverables-list',
        component: DeliverableList,
      },
      {
        path: 'crear',
        name: 'celulares-deliverables-create',
        component: DeliverableEditor
      }
    ]
  }
]

export default routes
