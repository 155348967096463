import PushersView from "../PushersView.vue";
import PusherList from "../Pages/PusherList.vue";
import PusherEditor from "../Pages/PusherEditor.vue";
import PusherUpload from '../Pages/PusherUpload.vue'

const routes = [
  {
    path: 'impulsadores',
    component: PushersView,
    children: [
      {
        path: '',
        name: 'celulares-pushers-list',
        component: PusherList
      },
      {
        path: 'crear',
        name: 'celulares-pushers-create',
        component: PusherEditor
      },
      {
        path: ':id/editar',
        name: 'celulares-pushers-edit',
        component: PusherEditor,
      },
      {
        path: 'carga',
        name: 'celulares-pushers-upload',
        component: PusherUpload
      }
    ]
  },
]

export default routes
