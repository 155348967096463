<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar impulsador" : "Nuevo impulsador" }}
      </h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12">
          <b-card
            tag="article"
            no-body
            style="max-width: 760px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{
                  editing
                    ? "Datos del impulsador"
                    : "Datos del nuevo impulsador"
                }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body px-0 pb-0">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section pt-0">
                  <h2 class="custom-card-subtitle mb-4">
                    Información personal
                  </h2>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="identifierType">Tipo de documento *</label>

                        <FormSelect
                          id="identifierType"
                          :show-error="
                            $v.form.identifierType.$error &&
                            !$v.form.identifierType.required
                          "
                          v-model="form.identifierType"
                          :items="identifierTypes"
                          defaultOption="Elegir tipo de documento"
                        />

                        <form-error
                          message="Tipo de documento es requerido"
                          v-if="
                            $v.form.identifierType.$error &&
                            !$v.form.identifierType.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="identifier">Número de documento *</label>

                        <FormInput
                          id="identifier"
                          type="number"
                          maxlength="20"
                          placeholder="Ingresar número de documento"
                          :show-error="
                            $v.form.identifier.$error
                          "
                          v-model="form.identifier"
                        />

                        <form-error
                          message="Número de documento es requerido"
                          v-if="
                            $v.form.identifier.$error &&
                            !$v.form.identifier.required
                          "
                        ></form-error>
                        <form-error
                          message="El número de documento debe ser numérico y tener entre 8 y 12 dígitos"
                          v-if="
                            $v.form.identifier.$error &&
                            (!$v.form.identifier.minLength || !$v.form.identifier.maxLength)
                          "
                        ></form-error>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group mb-0">
                        <label for="name">Nombres *</label>

                        <FormInput
                          id="name"
                          type="text"
                          placeholder="Ingresar nombres"
                          :show-error="
                            $v.form.names.$error
                          "
                          v-model="form.names"
                        />

                        <form-error
                          message="Nombres son requeridos"
                          v-if="$v.form.names.$error && !$v.form.names.required"
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="form-group mb-0">
                        <label for="surname">Apellidos *</label>

                        <FormInput
                          id="surname"
                          type="text"
                          placeholder="Ingresar apellidos"
                          :show-error="
                            $v.form.surname.$error && !$v.form.surname.required
                          "
                          v-model="form.surname"
                        />

                        <form-error
                          message="Apellidos son requeridos"
                          v-if="$v.form.surname.$error && !$v.form.surname.required"
                        ></form-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section">
                  <h2 class="custom-card-subtitle mb-4">
                    Información de Ubicación
                  </h2>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="address">Dirección</label>
                        <FormInput
                          id="address"
                          type="text"
                          maxlength="100"
                          placeholder="Ingresar dirección"
                          v-model="form.address"
                        />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="ubigeo">Distrito *</label>
                        <SearchableDropdown
                          placeholder="Elegir distrito"
                          :options="districtList"
                          :oldValue="form.ubigeo"
                          @selected="form.ubigeo = $event"
                          :show-error="
                            $v.form.ubigeo.$error && !$v.form.ubigeo.required
                          "
                        />
                        <form-error
                          message="Distrito es requerido"
                          v-if="
                            $v.form.ubigeo.$error && !$v.form.ubigeo.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="zonalId">Zonal *</label>
                        <FormSelect
                          id="zonalId"
                          :show-error="
                            $v.form.zonalId.$error && !$v.form.zonalId.required
                          "
                          v-model="form.zonalId"
                          :items="zonalList"
                          defaultOption="Elegir zonal"
                        />
                        <form-error
                          message="Zonal es requerido"
                          v-if="
                            $v.form.zonalId.$error && !$v.form.zonalId.required
                          "
                        ></form-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section">
                  <h2 class="custom-card-subtitle mb-4">
                    Información de Contacto
                  </h2>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="phone">Celular</label>
                        <FormInput
                          id="phone"
                          type="number"
                          min="0"
                          maxlength="9"
                          placeholder="Ingresar número celular"
                          :show-error="
                            $v.form.phoneNumber.$error
                          "
                          v-model="form.phoneNumber"
                        />

                        <form-error
                          message="Ingrese un número válido"
                          v-if="!$v.form.phoneNumber.numeric && $v.form.phoneNumber.$error"
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="email">Correo Electrónico</label>
                        <FormInput
                          id="email"
                          type="email"
                          maxlength="100"
                          placeholder="Ingresar correo electrónico"
                          :show-error="
                            $v.form.email.$error && !$v.form.email.email
                          "
                          v-model="form.email"
                        />

                        <form-error
                          message="Ingrese un correo electrónico válido"
                          v-if="!$v.form.email.email && $v.form.email.$error"
                        ></form-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section border-0 pb-0 mb-0">
                  <h2 class="custom-card-subtitle mb-4">Información Laboral</h2>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="type">Tipo *</label>

                        <FormSelect
                          id="type"
                          :show-error="
                            $v.form.type.$error && !$v.form.type.required
                          "
                          v-model="form.type"
                          :items="typeList"
                          defaultOption="Elegir tipo"
                        />

                        <form-error
                          message="Tipo es requerido"
                          v-if="$v.form.type.$error && !$v.form.type.required"
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="type">Clúster</label>

                        <FormSelect
                          id="type"
                          v-model="form.cluster"
                          :items="clusterList"
                          defaultOption="Elegir clúster"
                        />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="rechargerNumber"
                          >Número de recargador</label
                        >

                        <FormInput
                          id="rechargerNumber"
                          type="number"
                          placeholder="Ingresar Número de recargador"
                          v-model="form.rechargerNumber"
                        />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="erpCode">Código Comercial ERP</label>

                        <FormInput
                          id="erpCode"
                          type="number"
                          placeholder="Ingresar Código Comercial ERP"
                          :show-error="
                            $v.form.erpCode.$error && !$v.form.erpCode.numeric
                          "
                          v-model="form.erpCode"
                        />

                        <form-error
                          message="Ingrese un número válido"
                          v-if="
                            $v.form.erpCode.$error && !$v.form.erpCode.numeric
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="supervisor">Supervisor</label>

                        <FormInput
                          id="supervisor"
                          type="text"
                          maxlength="100"
                          placeholder="Ingresar supervisor"
                          v-model="form.supervisor"
                        />

                        <!-- <SearchableDropdown
                          placeholder="Elegir supervisor"
                          :options="supervisorList"
                          :oldValue="form.supervisor"
                          @selected="form.supervisor = $event"
                        /> -->

                        <!-- :show-error="$v.form.supervisor.$error && !$v.form.supervisor.required" -->
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="admissionDate">Fecha de ingreso</label>

                        <FormDatepicker
                          label-id="admissionDate"
                          @show="showDatepicker = true"
                          @hidden="showDatepicker = false"
                          v-model="form.admissionDate"
                        />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="contractFinishDate">Fecha de cese</label>

                        <FormDatepicker
                          label-id="contractFinishDate"
                          @show="showDatepicker = true"
                          @hidden="showDatepicker = false"
                          v-model="form.contractFinishDate"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section border-0 pt-0">
                  <div class="form-group-buttons text-right">
                    <a
                      @click="$router.go(-1)"
                      class="button button-light"
                    >
                      {{ editing ? "Volver" : "Cancelar" }}
                    </a>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  Constants,
  FormError,
  FormInput,
  FormSelect,
  SearchableDropdown,
  FormDatepicker,
} from "wize-admin";

import { PusherService } from "../../Core/Services";

import { required, email, numeric, minLength, maxLength } from "vuelidate/lib/validators";

import { Constants as LocalConstants } from "@/core/utils";

import geoDataMixin from "@/core/Mixins/geoDataMixin";

export default {
  mixins: [geoDataMixin],
  data() {
    return {
      showDatepicker: false,
      identifierTypes: [
        LocalConstants.IdentifierType.DNI,
        LocalConstants.IdentifierType.RUC,
        LocalConstants.IdentifierType.CE,
        LocalConstants.IdentifierType.PASAPORTE,
      ],
      districtList: [],
      zonalList: [],
      supervisorList: [],
      clusterList: [],
      typeList: [],
      form: {
        identifier: null,
        identifierType: null,
        names: null,
        surname: null,
        email: null,
        phoneNumber: null,
        zonalId: null,
        address: null,
        ubigeo: null,
        supervisor: null,
        rechargerNumber: null,
        type: null,
        erpCode: null,
        admissionDate: null,
        contractFinishDate: null,
        cluster: null,
      },
      editing: false,
      loading: true,
    };
  },
  components: {
    FormError,
    FormSelect,
    FormInput,
    FormDatepicker,
    SearchableDropdown
  },
  validations: {
    form: {
      identifierType: { required },
      identifier: { required, minLength: minLength(8), maxLength: maxLength(12) },
      names: { required },
      surname: { required },
      email: { email },
      phoneNumber: { numeric },
      zonalId: { required },
      ubigeo: { required },
      type: { required },
      erpCode: { numeric }
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
    "form.identifier": async function (val) {
      if (this.loading) return;
      this.$v.form.identifier.$touch();
      if (this.$v.form.identifier.$error) return;
      if (
        this.form.identifierType === LocalConstants.IdentifierType.DNI.key &&
        this.form.identifier &&
        this.form.identifier.length === 8
      ) {
        try {
          this.$store.dispatch("app/loading", true);
          const resp = (await PusherService.queryDni(val)).payload;
          this.form.names = resp.name;
          this.form.surname = resp.surname;
        } catch (ex) {
          Alert.error(ex.errors.message);
          console.error(ex);
        } finally {
          this.$store.dispatch("app/loading", false);
        }
      }
    },
  },
  methods: {
    async save() {
      this.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      this.$store.dispatch("app/loading", true);
      const data = {
        ...this.form,
      };

      try {
        let response;
        if (!this.editing) {
          response = await PusherService.save(data);
        } else {
          response = await PusherService.update(this.$route.params.id, data);
        }

        if (response.status == "OK") {
          Alert.success("Se almacenó la información correctamente");
          if (!this.editing) {
            this.$router.push({
              name: "celulares-pushers-edit",
              params: { id: response.payload.id },
            });
            this.editing = true;
          }
        }
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await PusherService.get(id);

        this.form = {
          ...response.payload,
          zonalId: response.payload.zonal?.id,
          erpCode: response.payload.erpcode,
          type: response.payload.type?.id,
          cluster: response.payload.cluster?.id,
        };

        this.editing = true;
      } catch (ex) {
        console.error(ex);
        let message = ex.errors.message;
        if (message === "No encontrado")
          message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () =>
          this.$router.push({ name: "products-list" })
        );
      } finally {
        this.$store.dispatch("app/loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
  },
  async created() {
    await this.getUbigeos();
    await this.getZonalList();
    await this.getClusterList();
    await this.getTypeList(LocalConstants.SegmentType.PUSHER.key);
    if (this.$route.params.id) await this.load(this.$route.params.id)
    else this.loading = false;
  },
};
</script>

<style lang="stylus"></style>
