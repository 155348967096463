<template>
  <div class="page-container product-editor">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar Anexo" : "Nuevo Anexo" }}
      </h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12">
          <b-card
            tag="article"
            no-body
            style="max-width: 760px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{ editing ? "Datos del Anexo" : "Datos del nuevo Anexo" }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body px-0 pb-0">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section pt-0">
                  <h2 class="custom-card-subtitle mb-4">
                    Información del Impulsador
                  </h2>
                  <div class="form-row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="pusher">Impulsador *</label>

                        <FormSelect
                          id="pusher"
                          :show-error="
                            $v.form.pusher.$error && !$v.form.pusher.required
                          "
                          v-model="form.pusher"
                          :items="pusherList"
                          defaultOption="Elegir impulsador"
                        />

                        <form-error
                          message="Tipo de documento es requerido"
                          v-if="
                            $v.form.pusher.$error && !$v.form.pusher.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="form-group">
                        <label for="name"
                          >Nombres y Apellidos *</label
                        >

                        <FormInput
                          id="name"
                          type="text"
                          placeholder="Ingresar nombres y apellidos"
                          :show-error="
                            $v.form.name.$error && !$v.form.name.required
                          "
                          v-model="form.name"
                        />

                        <form-error
                          message="Apellidos y nombres completos son requeridos"
                          v-if="$v.form.name.$error && !$v.form.name.required"
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="identifierType">Tipo de documento *</label>

                        <FormSelect
                          id="identifierType"
                          :show-error="
                            $v.form.identifierType.$error &&
                            !$v.form.identifierType.required
                          "
                          v-model="form.identifierType"
                          :items="identifierTypes"
                          defaultOption="Elegir tipo de documento"
                        />

                        <form-error
                          message="Tipo de documento es requerido"
                          v-if="
                            $v.form.identifierType.$error &&
                            !$v.form.identifierType.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="identifier">Número de documento *</label>

                        <FormInput
                          id="identifier"
                          type="number"
                          maxlength="20"
                          placeholder="Ingresar número de documento"
                          :show-error="
                            $v.form.identifier.$error &&
                            !$v.form.identifier.required
                          "
                          v-model="form.identifier"
                        />

                        <form-error
                          message="Número de documento es requerido"
                          v-if="
                            $v.form.identifier.$error &&
                            !$v.form.identifier.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="zonales">Zonal</label>

                        <FormSelect
                          id="zonales"
                          :show-error="
                            $v.form.zonal.$error && !$v.form.zonal.required
                          "
                          v-model="form.zonal"
                          :items="zonalList"
                          defaultOption="Elegir zonal"
                        />

                        <form-error
                          message="Zonal es requerido"
                          v-if="$v.form.zonal.$error && !$v.form.zonal.required"
                        ></form-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section pb-0 border-0">
                  <h2 class="custom-card-subtitle mb-4">
                    Información del Anexo
                  </h2>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="pdvNoTelco">N° Documento del PDV No Telco *</label>
                        <FormInput
                          id="pdvNoTelco"
                          type="number"
                          min="0"
                          step="1"
                          placeholder="Ingresar N° Documento del PDV No Telco"
                          :show-error="
                            $v.form.pdvNoTelco.$error && !$v.form.pdvNoTelco.required
                          "
                          v-model="form.pdvNoTelco"
                        />
                        <form-error
                          message="Número celular corporativo es requerido"
                          v-if="$v.form.pdvNoTelco.$error && !$v.form.pdvNoTelco.required"
                        ></form-error>

                        <form-error
                          message="Ingrese un número válido"
                          v-if="!$v.form.pdvNoTelco.numeric && $v.form.pdvNoTelco.$error"
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="codPadre">Tipo Documento del Cod Padre</label>

                        <FormSelect
                          id="codPadre"
                          :show-error="
                            $v.form.codPadre.$error && !$v.form.codPadre.required
                          "
                          v-model="form.codPadre"
                          :items="codPadreList"
                          defaultOption="Elegir tipo Documento del Cod Padre"
                        />

                        <form-error
                          message="Canal es requerido"
                          v-if="
                            $v.form.codPadre.$error && !$v.form.codPadre.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="codPadreIdentifier">N° Documento del Cod Padre *</label>

                        <FormInput
                          id="codPadreIdentifier"
                          type="number"
                          maxlength="20"
                          placeholder="Ingresar N° Documento del Cod Padre"
                          :show-error="
                            $v.form.codPadreIdentifier.$error &&
                            !$v.form.codPadreIdentifier.required
                          "
                          v-model="form.codPadreIdentifier"
                        />

                        <form-error
                          message="N° Documento del Cod Padre es requerido"
                          v-if="
                            $v.form.codPadreIdentifier.$error &&
                            !$v.form.codPadreIdentifier.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="status">Estado *</label>

                        <FormSelect
                          id="status"
                          :show-error="
                            $v.form.status.$error && !$v.form.status.required
                          "
                          v-model="form.status"
                          :items="statusList"
                          defaultOption="Elegir estado"
                        />

                        <form-error
                          message="Estado es requerido"
                          v-if="
                            $v.form.status.$error &&
                            !$v.form.status.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group d-flex flex-column mb-0">
                        <label for="admissionDate">Fecha de Ingreso</label>

                        <FormDatepicker
                          label-id="admissionDate"
                          @show="showDatepicker = true"
                          @hidden="showDatepicker = false"
                          v-model="form.admissionDate"
                        />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group d-flex flex-column mb-0">
                        <label for="terminationDate">Fecha de Cese</label>

                        <FormDatepicker
                          label-id="terminationDate"
                          @show="showDatepicker = true"
                          @hidden="showDatepicker = false"
                          v-model="form.terminationDate"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section border-0 pt-0">
                  <div class="form-group-buttons text-right">
                    <a
                      @click="$router.go(-1)"
                      class="button button-light"
                    >
                      {{ editing ? "Volver" : "Cancelar" }}
                    </a>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  Constants,
  FormError,
  FormInput,
  FormSelect,
  FormDatepicker
} from "wize-admin";

import geoDataMixin from '@/core/Mixins/geoDataMixin'

import { DeliverableService } from "@/core/services";

import { required } from "vuelidate/lib/validators";

import { Constants as LocalConstants } from "@/core/utils";

export default {
  mixins: [geoDataMixin],
  data() {
    return {
      zonalList: [],
      pusherList: [],
      codPadreList: [],
      statusList: [
        LocalConstants.DeliverablesStatus.ACTIVE,
        LocalConstants.DeliverablesStatus.INACTIVE
      ],
      showDatepicker: false,
      identifierTypes: [
        LocalConstants.IdentifierType.DNI,
        LocalConstants.IdentifierType.RUC,
        LocalConstants.IdentifierType.CE,
        LocalConstants.IdentifierType.PASAPORTE,
      ],
      form: {
        pusher: null,
        identifierType: null,
        name: null,
        pdvNoTelco: null,
        codPadre: null,
        codPadreIdentifier: null,
        status: null,
        admissionDate: null,
        terminationDate: null,
        zonal: null,
      },
      editing: false,
    };
  },
  components: {
    FormError,
    FormSelect,
    FormInput,
    FormDatepicker
  },
  validations: {
    form: {
      pusher: { required },
      identifierType: { required },
      identifier: { required },
      name: { required },
      pdvNoTelco: { required },
      codPadre: { required },
      codPadreIdentifier: { required },
      status: { required },
      admissionDate: { required },
      terminationDate: { required },
      zonal: { required },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  methods: {
    async save() {
      this.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      this.$store.dispatch("app/loading", true);
      const data = {};

      try {
        let response;
        if (!this.editing) {
          response = await DeliverableService.save(data);
        } else {
          response = await DeliverableService.update(this.$route.params.id, data);
        }

        if (response.status == "OK") {
          Alert.success("Se almacenó la información correctamente");
          if (!this.editing) {
            this.$router.push({
              name: "celulares-deliverables-edit",
              params: { id: response.payload.id },
            });
            this.editing = true;
          }
        }
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await DeliverableService.get(id);

        this.form = JSON.parse(JSON.stringify(response.payload))

        this.editing = true;
      } catch (ex) {
        console.error(ex);
        let message = ex.errors.message;
        if (message == "No encontrado")
          message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () =>
          this.$router.push({ name: "products-list" })
        );
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    // if (this.$route.params.id) await this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus"></style>
