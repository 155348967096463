import StatusView from "../StatusView.vue";
import StatusList from "../Pages/StatusList.vue";
import StatusDetailList from "../Pages/StatusDetailList.vue";
import PaymentCodeStatusDetailList from "../Pages/PaymentCodeStatusDetailList.vue";
import PaymentMarkAsPaid from '../Pages/PaymentMarkAsPaid.vue';

const routes = [
  {
    path: 'status',
    component: StatusView,
    children: [
      {
        path: '',
        name: 'celulares-status',
        component: StatusList
      },
      {
        path: ':periodId/detalle',
        name: 'celulares-status-details',
        component: StatusDetailList,
      },
      {
        path: ':periodId/detalle/:paymentCode',
        name: 'celulares-status-details-payment-code',
        component: PaymentCodeStatusDetailList,
      },
      {
        path: ':periodId/detalle/:paymentCode/:paymentId/marcar-como-pagado',
        name: 'celulares-status-payment-mark-as-paid',
        component: PaymentMarkAsPaid
      }
    ]
  },
]

export default routes
