<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar relación" : "Nueva relación" }}
      </h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12">
          <b-card
            tag="article"
            no-body
            style="max-width: 760px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{
                  editing
                    ? "Datos de la relación"
                    : "Datos de la nueva relación"
                }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body px-0 pb-0">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section pt-0">
                  <h2 class="custom-card-subtitle mb-4">
                    Información del código principal
                  </h2>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="type">Segmento *</label>

                        <FormSelect
                          id="type"
                          :show-error="
                            $v.form.segment.$error && !$v.form.segment.required
                          "
                          v-model="form.segment"
                          :items="segmentList"
                          defaultOption="Elegir segmento"
                        />

                        <form-error
                          message="Segmento es requerido"
                          v-if="
                            $v.form.segment.$error && !$v.form.segment.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group mb-0">
                        <label for="pusher">Impulsador *</label>

                        <SearchableDropdown
                          placeholder="Elegir impulsador"
                          :options="pusherList"
                          :oldValue="form.pusher"
                          @selected="form.pusher = $event"
                          :show-error="
                            $v.form.pusher.$error && !$v.form.pusher.required
                          "
                        />

                        <form-error
                          message="Impulsador es requerido"
                          v-if="
                            $v.form.pusher.$error && !$v.form.pusher.required
                          "
                        ></form-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section border-0 mb-0">
                  <h2 class="custom-card-subtitle mb-4">
                    Información del código bolsa
                  </h2>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group mb-0">
                        <label for="code">Código de bolsa *</label>
                        <FormInput
                          id="code"
                          type="text"
                          maxlength="100"
                          placeholder="Ingresar código de bolsa"
                          :show-error="
                            $v.form.code.$error && !$v.form.code.required
                          "
                          v-model="form.code"
                        />

                        <form-error
                          message="Código de bolsa es requerido"
                          v-if="$v.form.code.$error && !$v.form.code.required"
                        ></form-error>
                      </div>
                    </div>
                  </div>

                  <div class="form-group-buttons text-right">
                    <a @click="$router.go(-1)" class="button button-light">
                      {{ editing ? "Volver" : "Cancelar" }}
                    </a>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  Constants,
  FormError,
  FormInput,
  FormSelect,
  SearchableDropdown,
} from "wize-admin";

import { PusherService, Relationships } from "../../Core/Services";

import { required } from "vuelidate/lib/validators";

import { Constants as LocalConstants } from "@/core/utils";

export default {
  data() {
    return {
      showDatepicker: false,
      identifierTypes: [
        Constants.IdentifierType.DNI,
        Constants.IdentifierType.RUC,
        Constants.IdentifierType.CE,
        Constants.IdentifierType.PASSPORT,
      ],
      districtList: [],
      zonalList: [],
      supervisorList: [],
      clusterList: [],
      typeList: [],
      segmentList: [
        LocalConstants.SegmentType.PDV,
        LocalConstants.SegmentType.PUSHER,
      ],
      pusherList: [],
      form: {
        segment: null,
        pusher: null,
        code: null,
      },
      editing: false,
      loading: true,
    };
  },
  components: {
    FormError,
    FormSelect,
    FormInput,
    SearchableDropdown,
  },
  validations: {
    form: {
      segment: { required },
      pusher: { required },
      code: { required },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  methods: {
    async save() {
      this.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      this.$store.dispatch("app/loading", true);
      const data = {
        ...this.form,
      };

      try {
        let response;
        if (!this.editing) {
          response = await Relationships.save(data);
        } else {
          response = await Relationships.update(this.$route.params.id, data);
        }

        if (response.status == "OK") {
          Alert.success("Se almacenó la información correctamente");
          if (!this.editing) {
            this.$router.push({
              name: "celulares-pushers-edit",
              params: { id: response.payload.id },
            });
            this.editing = true;
          }
        }
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await Relationships.get(id);

        this.form = {
          ...response.payload,
          zonalId: response.payload.zonal?.id,
          erpCode: response.payload.erpcode,
          type: response.payload.type?.id,
          cluster: response.payload.cluster?.id,
        };

        this.editing = true;
      } catch (ex) {
        console.error(ex);
        let message = ex.errors.message;
        if (message === "No encontrado")
          message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () =>
          this.$router.push({ name: "products-list" })
        );
      } finally {
        this.$store.dispatch("app/loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
    async getPushers() {
      const params = {
        page: 1,
        size: 100,
      };

      const data = { search: "" };
      const response = await PusherService.list(data, params);

      response.payload.content.forEach((b) => {
        if (this.pusherList) {
          this.pusherList.push({ key: b.id, label: `${b.names} ${b.surname}` });
        }
      });
    },
  },
  async created() {
    await this.getPushers();
    if (this.$route.params.id) await this.load(this.$route.params.id);
    else this.loading = false;
  },
};
</script>

<style lang="stylus"></style>
