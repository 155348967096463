var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container product-editor"},[_c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.editing ? "Editar esquema" : "Nuevo esquema")+" ")])]),_c('div',{staticClass:"container px-0"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"760px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header d-flex align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.editing ? "Datos del supervisor" : "Datos del nuevo supervisor")+" ")])]),_c('b-card-body',{staticClass:"custom-card-body px-0 pb-0"},[_c('form',{staticClass:"custom-form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"form-section pt-0"},[_c('h2',{staticClass:"custom-card-subtitle mb-4"},[_vm._v("Información general")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nombre del Esquema *")]),_c('FormInput',{attrs:{"id":"name","type":"text","placeholder":"Ingresar nombre del esquema","show-error":_vm.$v.form.name.$error && !_vm.$v.form.name.required},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.$v.form.name.$error && !_vm.$v.form.name.required)?_c('form-error',{attrs:{"message":"Nombre del esquema es requerido"}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"aplicationType"}},[_vm._v("Tipo de Aplicación *")]),_c('FormSelect',{attrs:{"id":"aplicationType","show-error":_vm.$v.form.aplicationType.$error &&
                          !_vm.$v.form.aplicationType.required,"items":_vm.aplicationTypes,"defaultOption":"Elegir tipo de aplicación"},model:{value:(_vm.form.aplicationType),callback:function ($$v) {_vm.$set(_vm.form, "aplicationType", $$v)},expression:"form.aplicationType"}}),(
                          _vm.$v.form.aplicationType.$error &&
                          !_vm.$v.form.aplicationType.required
                        )?_c('form-error',{attrs:{"message":"Tipo de aplciación es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"period"}},[_vm._v("Periodo *")]),_c('FormInput',{attrs:{"id":"period","type":"text","maxlength":"20","placeholder":"Ingresar periodo","show-error":_vm.$v.form.period.$error && !_vm.$v.form.period.required},model:{value:(_vm.form.period),callback:function ($$v) {_vm.$set(_vm.form, "period", $$v)},expression:"form.period"}}),(
                          _vm.$v.form.period.$error && !_vm.$v.form.period.required
                        )?_c('form-error',{attrs:{"message":"Periodo es requerido"}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"cycle"}},[_vm._v("Ciclo *")]),_c('FormSelect',{attrs:{"id":"cycle","show-error":_vm.$v.form.cycle.$error && !_vm.$v.form.cycle.required,"items":_vm.cyclesList,"defaultOption":"Elegir ciclo"},model:{value:(_vm.form.cycle),callback:function ($$v) {_vm.$set(_vm.form, "cycle", $$v)},expression:"form.cycle"}}),(_vm.$v.form.cycle.$error && !_vm.$v.form.cycle.required)?_c('form-error',{attrs:{"message":"Ciclo es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group mb-0"},[_c('label',{attrs:{"for":"paymentCode"}},[_vm._v("Código de Pago *")]),_c('FormInput',{attrs:{"id":"paymentCode","type":"text","maxlength":"20","placeholder":"Ingresar código de Pago","show-error":_vm.$v.form.paymentCode.$error &&
                          !_vm.$v.form.paymentCode.required},model:{value:(_vm.form.paymentCode),callback:function ($$v) {_vm.$set(_vm.form, "paymentCode", $$v)},expression:"form.paymentCode"}}),(
                          _vm.$v.form.paymentCode.$error &&
                          !_vm.$v.form.paymentCode.required
                        )?_c('form-error',{attrs:{"message":"Código de Pago es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group mb-0"},[_c('label',{attrs:{"for":"zonales"}},[_vm._v("Zonal")]),_c('FormSelect',{attrs:{"id":"zonal","show-error":_vm.$v.form.zonal.$error && !_vm.$v.form.zonal.required,"items":_vm.zonalList,"defaultOption":"Elegir zonal"},model:{value:(_vm.form.zonal),callback:function ($$v) {_vm.$set(_vm.form, "zonal", $$v)},expression:"form.zonal"}}),(_vm.$v.form.zonal.$error && !_vm.$v.form.zonal.required)?_c('form-error',{attrs:{"message":"Zonal es requerido"}}):_vm._e()],1)])])]),_c('div',{staticClass:"form-section pb-0 border-0"},[_c('h2',{staticClass:"custom-card-subtitle mb-4"},[_vm._v(" Configuración de Clusters ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group mb-0"},[_c('label',{attrs:{"for":"cluster"}},[_vm._v("Tipo de Cluster")]),_c('FormSelect',{attrs:{"id":"cluster","show-error":_vm.$v.form.cluster.$error && !_vm.$v.form.cluster.required,"items":_vm.clusterList,"defaultOption":"Elegir cluster"},model:{value:(_vm.form.cluster),callback:function ($$v) {_vm.$set(_vm.form, "cluster", $$v)},expression:"form.cluster"}}),(
                          _vm.$v.form.cluster.$error && !_vm.$v.form.cluster.required
                        )?_c('form-error',{attrs:{"message":"Cluster es requerido"}}):_vm._e()],1)])])]),_c('div',{staticClass:"form-section border-0 pt-0"},[_c('div',{staticClass:"form-group-buttons text-right"},[_c('a',{staticClass:"button button-light",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.editing ? "Volver" : "Cancelar")+" ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])])])])])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }