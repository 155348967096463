<template>
  <nav class="navbar nav-header py-0">
    <div
      class="w-100 d-flex align-items-center justify-content-between position-relative"
    >
      <!-- Mobile -->
      <div class="d-flex align-items-center d-lg-none">
        <img
          src="@/core/assets/images/layout/logo-admin-2.png"
          alt="Phoenix"
          class="mr-2"
        />
        <span class="sidebar-title text-white w-50">MIRANA</span>
      </div>

      <div class="d-lg-none">
        <button
          type="button"
          class="bg-transparent px-0 border-0"
          @click="toggleMenu(true)"
        >
          <MenuIcon />
        </button>
      </div>

      <!-- Desktop -->
      <BusinessesDropdown class="d-none d-lg-inline-block" />

      <UserDropdown :user="user" @logout="logout()" class="d-none d-lg-block" />
    </div>
  </nav>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { UserDropdown, MenuIcon } from "wize-admin";

import BusinessesDropdown from "./layout/BusinessesDropdown.vue";

export default {
  name: "PageHeader",
  data() {
    return {
      showCart: false,
      categories: [],
      subcategories: [],
    };
  },
  components: {
    UserDropdown,
    MenuIcon,
    BusinessesDropdown
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    })
  },
  methods: {
    ...mapMutations({
      toggleMenu: "app/toggleMenu",
    }),
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push({ name: "login" }));
    },
  },
};
</script>

<style scoped lang="stylus"></style>
