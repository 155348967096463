import { http } from "@/http";

export default {
  list(data, params) {
    return http.post("/celulares/pushers/list", data, { params });
  },
  changeStatus(pusherId, status) {
    return http.put(`/celulares/pushers/${pusherId}/status`, null, {
      params: { status },
    });
  },
  save(data) {
    return http.post("/celulares/pushers", data);
  },
  update(pusherId, data) {
    return http.put(`/celulares/pushers/${pusherId}`, data);
  },
  get(pusherId) {
    return http.get(`/celulares/pushers/${pusherId}`);
  },
  queryDni(dni) {
    return http.get(`/celulares/pushers/dni/${dni}`);
  },
  download(data) {
    return http.post("/celulares/pushers/xlsx", data, { responseType: "blob" });
  },
};
