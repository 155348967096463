<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar cluster" : "Nuevo cluster" }}
      </h1>
    </div>
    <div>
      <form class="custom-form" @submit.prevent="save" novalidate>
        <b-card
          tag="article"
          no-body
          style="max-width: 760px"
          class="custom-card"
        >
          <b-card-header
            header-tag="section"
            class="custom-card-header d-flex align-items-center"
          >
            <h3>
              {{ editing ? "Datos del cluster" : "Datos del nuevo cluster" }}
            </h3>
          </b-card-header>
          <b-card-body class="custom-card-body p-0">
            <section class="form-section">
              <div class="form-row">
                <div class="col-12">
                  <div class="form-group mb-0">
                    <label for="description">Descripción *</label>
                    <input
                      id="description"
                      type="text"
                      maxlength="200"
                      v-model.trim="form.description"
                      autocomplete="off"
                      placeholder="Ingresar descripción"
                      class="custom-form-control"
                      :class="{
                        'custom-form-control-error': $v.form.description.$error,
                      }"
                    />
                    <FormError
                      v-if="
                        $v.form.description.$error &&
                        !$v.form.description.required
                      "
                      message="Descripción es requerida"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group-buttons text-right">
                <a
                  @click="$router.go(-1)"
                  class="button button-light"
                >
                  {{ editing ? "Volver" : "Cancelar" }}
                </a>
                <button class="button button-primary" type="submit">
                  Guardar
                </button>
              </div>
            </section>
          </b-card-body>
        </b-card>
      </form>
    </div>
  </div>
</template>

<script>
import { Alert, FormError } from "wize-admin";
import { ClusterService } from "@/core/services";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    FormError,
  },
  data() {
    return {
      loading: true,
      form: {
        description: null,
      },
      editing: false,
    };
  },
  validations: {
    form: {
      description: { required },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) {
        this.load(this.$route.params.id);
      } else {
        this.loading = false;
      }
    },
  },
  methods: {
    async save() {
      this.$v.$touch();
      if (this.$v.$error) return;
      try {
        this.$store.dispatch("app/loading", true);
        const data = { ...this.form };

        let response;
        if (!this.editing) response = await ClusterService.save(data);
        else
          response = await ClusterService.update(
            this.$route.params.id,
            data
          );
        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "celulares-config-cluster-edit",
            params: { id: response.payload.id },
          });
          this.editing = true;
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await ClusterService.get(id);
        const payload = response.payload;

        this.form = {
          ...payload,
        };

        this.editing = true;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
        this.$router.push({ name: "celulares-config-cluster-list" });
      } finally {
        this.$store.dispatch("app/loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
  },
  async created() {
    if (this.$route.params.id) {
      this.load(this.$route.params.id);
    } else {
      this.loading = false;
    }
  },
};
</script>

<style lang="stylus" scoped></style>
