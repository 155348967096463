import SalesPointView from "../SalesPointView.vue";
import SalesPointsList from "../Pages/SalesPointsList.vue";
import SalesPointEditor from "../Pages/SalesPointEditor.vue";
import SalesPointUpload from "../Pages/SalesPointUpload.vue";

const routes = [
  {
    path: 'puntos-de-venta',
    component: SalesPointView,
    children: [
      {
        path: '',
        name: 'celulares-sales-points-list',
        component: SalesPointsList
      },
      {
        path: 'crear',
        name: 'celulares-sales-points-create',
        component: SalesPointEditor
      },
      {
        path: ':id/editar',
        name: 'celulares-sales-points-edit',
        component: SalesPointEditor
      },
      {
        path: 'carga',
        name: 'celulares-sales-points-upload',
        component: SalesPointUpload
      }
    ]
  },
]

export default routes
